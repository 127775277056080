import React from 'react'
import {useState,useEffect} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

import {getRoute} from '../../../api/methods';

export default function Excel() {
    const[result,setResult]= useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
 
    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/assets');
        setLoading(false);
        if (data) {
            setAssets(data.assets);
        }
    }, []);
    console.log(assets);


    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
        <div className="viewEmployee container">
        <h3 className="mt-3 text-success">
            <center>Export Assets Data into EXCEL Sheet</center>
        </h3>
        <div className="row mt-4">
        <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-info mb-3 pull-right"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download"/>
           <table className="table" id="table-to-xls">
            <thead className="thead-info">

            <tr>
                <th>Description</th>
                <th>Acquisition_Value</th>

                <th>Code</th>
                <th>Year</th>

                <th>Sub Type</th>
                <th>Facility</th>

                <th>Supplier</th>
            </tr>
            </thead>

            {loading ? 
                <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <tbody>
           
                 {assets.map((res)=>
                    <tr>
                    <td>{res.tbl_assetDesc}</td>
                    <td>{res.tbl_assetAcquisValue}</td>

                    <td>{res.tbl_assetCode}</td>
                    <td>{new Date(res.createdAt).getFullYear()}</td>

                    <td>{res.subtype.tbl_subtypeName}</td>
                    <td>{res.facility.tbl_facilityName}</td>
                    
                    <td>{res.supplier.tbl_supplierFullName}</td>

                    </tr>
                  )}   
               
            </tbody>
            }   
        </table>
     </div>
    </div>
    </div>

    </div>
    </div>
    )
}
