import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom"; 

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';
import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ShowCondition() {

    const [condition, setCondition] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        } 

        setLoading(true);
        const data = await getRoute('/conditions/view/'+id);
        setLoading(false);
        if (data) {
            setCondition(data.condition);
            //return false;
        }

    }, []);


    if(!loading && !condition){

        return <p>No result found</p>; 
    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
            {loading ? 
              <center><img  src={"../../dist/img/loading.gif"} alt="Loading...." /></center> 
            :
            <div className="editAssetType">
            {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">
   
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
   
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Condition Details</span>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        Name: {condition.tbl_conditionName}
    </span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
    Created On : {" "+new Date(condition.createdAt).getDate()+
          "/"+(new Date(condition.createdAt).getMonth()+1)+
          "/"+new Date(condition.createdAt).getFullYear()}
    </span>
    </div>
   
     <Link to="../conditionReport">
       <button className="btn btn-info">Back</button>
     </Link>

    </div>
    </div>
    
    </div>   




            </div>}
        </div>

        </div>
        </div>
    )
}
