import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ShowLevel() {

    const [level, setLevel] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/levels/view/'+id);
        setLoading(false);
        if (data) {
            setLevel(data.level);
            //return false;
        }

    }, []);


    if(!loading && !level){

        return <p>No Level</p>; 
    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 
        <div className="content-wrapper bg-white">

            {loading ? 
              <center><img  src={"../../dist/img/loading.gif"} alt="Loading...." /></center> 
            :      
        <div className="editAssetType">
        {/* <h1 className="userTitle"> Edit User</h1> */}
    {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
    <div className="userTitleContainer">
       </div>

<div className="userContainer">
<div className="userShow">

<div className="userShowBottom">
<span className="userShowTitle text-dark font-weight-bold">Level (Qualification) Details</span>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Name: {level.tbl_levelQualification}
</span>
</div>
<Link to="../levelReport">
<button className="btn btn-info">Back</button>
 </Link>
</div>
</div>

</div>   



        {/* Footer */}
        <div className="footerHeight1">
           <hr />
                       
       </div>
        </div>}
    </div>

    </div>
    </div>
    )
}
