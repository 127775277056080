import React from 'react'

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

// Icons
import {Close, DeleteOutline, Send} from "@material-ui/icons";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddFacility() {

    const [facName, setFacName] = useState('');
    const [facDesc, setFacDesc] = useState('');

    const [loading, setLoading] = useState(false);
    let history = useHistory();

    const [facCode, setFacCode] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');


    useEffect(() => {

        if(localStorage.adminEmailToken){

           const decoded = jwt_decode(localStorage.adminEmailToken); 
  
           setUserEmail(decoded.tbl_staffEmail);
           setUserRole(decoded.staffFoundRole.name);
  
           setUserFullName(decoded.tbl_staffFullName);
          setUserId(decoded.id);

        }
  
    });

    const saveFacility = (event)  =>  {
        event.preventDefault();

        if(facName.trim() == '' || facName.trim() == null ){

            alert("Name of facility is required");
            return false;

        }else if(facCode.trim() == '' || facCode.trim() == null ){

            alert("Code is required");
            return false;

        }else if(facDesc.trim() == '' || facDesc.trim() == null ){

            alert("Description of facility is required");
            return false;


        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {
                "tbl_facilityName": facName,
                "tbl_facilityCode": facCode,
                "tbl_facilityDesc": facDesc,
                "tbl_facilityToken": randomString(5),
                "tbl_facilityStatus": true,
            }

            setLoading(true);
            const data =  postRoute('/facility',postData );
            
            if (data) {

                alert(facName+" has been added");
                setLoading(false);

                history.push("/registeredFacilitiesReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }



    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
              <div className="addAssetType">
            <h3 className="newUserTitle text-center text-white bg-info">Add New Facility</h3>
            <form className="newUserForm" onSubmit={saveFacility}>
            <div className="newUserItem">
                    <label>Facility/Building Name</label>
                    <input onChange={(e) => setFacName(e.target.value)} type="text" placeholder="Muhabura" name="facilityName" id="facilityName" required />
                </div>

                <div className="newUserItem">
                    <label>Code</label>
                    <input onChange={(e) => setFacCode(e.target.value)} type="text" placeholder="POO1" name="facilityCode" id="facilityCode" required />
                </div>


                <div className="newUserItem">
                    <label>Facility/Building Description</label>
                    
                    <textarea onChange={(e) => setFacDesc(e.target.value)}  name="facilityDesc" id="facilityDesc"  placeholder="Laboratory"  cols="30" rows="10" required></textarea>
                </div>
            
                
                
               
                
               {/* <div className="buttons">                */}

                { loading ? <h4>Processing ...</h4>:
                  <button className="newUserButton bg-info">
                    <Send className="text-warning"/> Add 
                  </button>
                } 

                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button>
                 */}
                {/* </div> */}
            </form>
            
            </div>
            
        </div>

        </div>
            
        </div>
    )
}
