import React from 'react'

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddCustodianAsset() {

    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState([]);

    const [assetId, setAssetId] = useState('');
    const [staffId, setStaffId] = useState('');

    const [from, setFrom] = useState('');
    const [to, setTo] = useState(''); 

    const [user, setUser] = useState('');  
    const [facility, setFacility] = useState('');
    const [facilities, setFacilities] = useState([]);

    const [asset, setAsset] = useState(null);
    const { id } = useParams();

    let history = useHistory();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/staffs');
        setLoading(false);
        if (data) {
            setStaffs(data.staffs);
        }
        
        //assets
        const assetsData = await getRoute('/assets/asset/'+id);
        setLoading(false);
        if (assetsData) {
            setAsset(assetsData.asset);
            //return false;
        }

        setAssetId(id);

        const decoded = jwt_decode(localStorage.adminEmailToken); 
        setUser(decoded.id);

        const facData = await getRoute('/facility');
        if (facData) {
            setFacilities(facData.facilities);
        }


    }, []);


    if(!loading && !asset){

        return <p>No Result</p>; 
    }
    

    const saveCustodyAsset = (event)  =>  {
        event.preventDefault();

        if(assetId.trim() == '' || assetId.trim() == null ){

            alert("Asset is required");
            return false;

        }else if(staffId.trim() == '' || staffId.trim() == null ){

            alert("Employee is required");
            return false;

        }else if(from.trim() == '' || from.trim() == null ){

            alert("Starting date is required");
            return false;

        }else if(to.trim() == '' || to.trim() == null ){

            alert("Expected ending date  is required");
            return false;

        }else if(user.trim() == '' || user.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(facility.trim() == '' || facility.trim() == null ){

            alert("Facility is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_staffID": staffId,
                "tbl_custodassetFrom": from,
                "tbl_custodassetTo": to,
                "tbl_custodassetRecordedBy": user,
                "tbl_assetID": assetId,
                "tbl_custodassetToken": randomString(5),
                "tbl_custodassetStatus": true,

                "tbl_facilityID": facility,
            }

            setLoading(true);

            axios.post('http://localhost:8080/api/v1/custody-assets', postData)
        
            .then(res =>{

                console.log(res.data);
    
                if(res.data.user){

                    setLoading(false);

                    alert("Asset has been assigned to an employee");
                    history.push("/custodianAssignedAssetReport");


                }else if(res.data.error){

                    setLoading(false);
                    alert("Process Failed");
                    history.push("/custodianAssignedAssetReport");
                    
                }else{

                    alert('Something went wrong');

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            });    


        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
            : 
        <div className="addNewAsset">

    <h3 className="newUserTitle text-center text-white bg-info">
        Assign asset: 
        <b className="text-warning">
            {asset.tbl_assetDesc} -  {asset.tbl_assetCode+" "}
        </b>
         to Custodian 
   </h3>
                <form className="newUserForm" onSubmit={saveCustodyAsset}>

                    <div className="newUserItem">
                    <label>Employee </label>
                    <select onChange={(e) => setStaffId(e.target.value)} name="employeeName" id="employeeName" className="newUserSelect">
                    <option value="">---Employee Name----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          staffs.map(staff =>(
                            <option value={staff.id} >
                             {staff.tbl_staffFullName} - {staff.tbl_staffNID}
                            </option>
                        )) }
                    </select>
                    </div>


                    <div className="newUserItem"> 
                        <label>Facility</label>
                        <select onChange={(e) => setFacility(e.target.value)} name="assetFacility" id="assetFacility" className="newUserSelect">
                            <option value="">---Facility----</option>
                            { loading ? <option value="">Loading ...</option> : 

                              facilities.map(fac =>(
                                <option value={fac.id} >
                                  {fac.tbl_facilityName}
                                </option>
                            )) }
                        </select>
                    </div>
               
                    <div className="newUserItem">
                        <label>From</label>
                        <input onChange={(e) => setFrom(e.target.value)} type="date" placeholder="from" name="from" id="from" />
                    </div>  
                    <div className="newUserItem">
                        <label>To</label>
                        <input onChange={(e) => setTo(e.target.value)} type="date" placeholder="to" name="to" id="to" />
                    </div>            
             
                    {/* <div className="buttons">                */}
                    <button className="newUserButton bg-info">
                       <Send className="text-warning"/> Assign
                    </button>
            {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
                    {/* </div>  */}
                </form>
                
          
          </div>}
    </div>

    </div>
    </div>
    )
}
