import React from 'react'

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ShowAllCustodianAsset() {

    const [custodasset, setCustodAsset] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

      if(localStorage.adminEmailToken){
        
        const decoded = jwt_decode(localStorage.adminEmailToken); 
  
        setUserEmail(decoded.tbl_staffEmail);
        setUserRole(decoded.staffFoundRole.name);
  
        setUserFullName(decoded.tbl_staffFullName);
       setUserId(decoded.id);
  
      } 

        setLoading(true);
        const data = await getRoute('/custody-assets/view/'+id);
        setLoading(false);
        if (data) {
            setCustodAsset(data.custodasset);
            //return false;
        }

    }, []);


    if(!loading && !custodasset){

        return <p>No asset</p>; 
    }

    //console.log(custodasset);




    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="editNewAsset">

                         {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">
 
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
    
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
        {custodasset.staff.tbl_staffNID}
    </span>
    <span className="userShowUserTitle">
       {custodasset.staff.tbl_staffFullName}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Asset Details</span>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
       Asset Code: {custodasset.asset.tbl_assetCode}
    </span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Asset Name: {custodasset.asset.tbl_assetDesc}
    </span>
    </div>

    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Asset SN: {custodasset.asset.tbl_assetSN}
    </span>
    </div>
    
    

    </div>
    </div>
    <div className="userUpdate">
        
    <form className="userUpdateForm">
    <div className="userUpdateLeft">
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        Received on: {custodasset.createdAt}
    </span>
    </div>

    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        {custodasset.tbl_custodassetStatus == true ? "Still there" : "Moved on: "+custodasset.updatedAt}
    </span>
    </div>
 
 
    <div className="userShowInfo">
    {/* Icon */}
    
    <span className="userShowTitle text-dark">
        Duration
    </span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      From: {custodasset.tbl_custodassetFrom}
    </span>
    </div>

    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      To: {custodasset.tbl_custodassetTo}
    </span>
    </div>


    <div className="row">

      <div className="col-md-6">
        <Link to="/custodianAssignedAssetReport"> 
           <button className="btn btn-info">Back</button>
        </Link>
      </div>

      {  userRole == "asset-manager" ? 

        <div className="col-md-6">
          <Link to={"../re-assign/"+id} > 
            <button className="btn btn-success">
              Reassign
            </button>
          </Link>
        </div>

        :
        null
      }


    </div>

  
    </div>
    </form>
    </div>
    </div>


    {/* Footer Call */}
    <div className="footerHeight1">
               <hr />
                           
           </div>
    </div>}
            
        </div>

        </div>
        </div>
    )
}
