import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import { DataGrid } from '@mui/x-data-grid';

export default function ShowSupplier() {

    const [supplier, setSupplier] = useState(null);
    const [loading, setLoading] = useState(false);

    const [supplLoading, setSupplLoading] = useState(false);
    const [supplierTypes, setSupplierTypes] = useState([]);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/suppliers/view/'+id);
        setLoading(false);
        if (data) {
            setSupplier(data.supplier);
            //return false;
        }

        //custody assets
        setSupplLoading(true);
        const supplTypeData = await getRoute('/supplier-types/'+id);
        setSupplLoading(false);
        if (supplTypeData) {
            setSupplierTypes(supplTypeData.supplierTypes);

            //console.log(supplTypeData.supplierTypes);
        }

    }, []);


    if(!loading && !supplier){

        return <p>No Supplier</p>; 
    }


    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
            {loading ? 
              <center><img  src={"../../dist/img/loading.gif"} alt="Loading...." /></center> 
            : 
        <div className="editEmployee">
         {/* <h1 className="userTitle"> Edit User</h1> */}
    {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
    <div className="userTitleContainer">
      
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
     <div className="userShoeTopTitle">
    <span className="userShowUsername">
        {supplier.tbl_supplierFullName}
    </span>
    <span className="userShowUserTitle">
        {supplier.sector.tbl_sectorName}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark font-weight-bold">Supplier's Details</span>
   
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        National ID: {supplier.tbl_supplierNid}
    </span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        TIN: {supplier.tbl_supplierTin}
    </span>
    </div>
    
    </div>
    </div>
    <div className="userUpdate">

    <form className="userUpdateForm">
    <div className="userUpdateLeft">
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        E-Mail: {supplier.tbl_supplierEmail}
    </span>
    </div>
  
    <div className="userShowInfo">
    {/* Icon */}
    
    <PhoneAndroid className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        Phone: {supplier.tbl_supplierPhone}
    </span>
    </div>

    <div className="row">

        <div className="col-md-6">
          <Link to="/suppliersReport">
            <button className="btn btn-info">Back</button>
          </Link>
        </div>

        <div className="col-md-6">
          <Link to={"/add-item/"+id}>
            <button className="btn btn-success">
                <i className="fa fa-plus"></i>
                Add New
            </button>
          </Link>
        </div>
    </div>


    </div>
     </form>
    
    </div>
    </div>

    <center>
      <h4>
        All type of assets are/is he suppling  
      </h4>
    </center>

    {supplLoading  ? 
      <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
        :

        <table className="table" >

            <thead className="thead-info">

                <tr>
                    <th>Type of assets</th>
                    <th>Started On</th>
                </tr>
            </thead>

            <tbody>
              {
                supplierTypes.map((res)=>

                    <tr>
                        <td>{res.typeDetail.tbl_typeName}</td>
                        <td>
                            {
                            new Date(res.createdAt).getDate()+
                            "/"+(new Date(res.createdAt).getMonth()+1)+
                           "/"+new Date(res.createdAt).getFullYear()
                           }
                           </td>

                    </tr>
                )
              }

            </tbody>

        </table>
      
    }

    {/* Footer Call */}
    <div className="footerHeight1">
           <hr />
                       
       </div>
    </div>}
    </div>

    </div>
    </div>
    )
}
