import React from 'react'
import { Link } from 'react-router-dom';

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddNewAsset() {

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [subtypes, setSubTypes] = useState([]);
    const [facilities, setFacilities] = useState([]);

    const [suppliers, setSuppliers] = useState([]);

    const [desc, setDesc] = useState('');
    const [acquisition, setAcquisition] = useState('');

    const [code, setCode] = useState('');
    const [manufacture, setManufacture] = useState('');

    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');

    const [note, setNote] = useState('');
    const [year, setYear] = useState('');

    const [typeId, setTypeId] = useState('');
    const [subtypeId, setSubtypeId] = useState('');

    const [facility, setFacility] = useState('');
    const [supplier, setSupplier] = useState('');

    const [photo, setPhoto] = useState('');
    const [residual, setResidual] = useState('');

    const [initialSubType, setInitialSubType] = useState([]);
    const [subLoading, setSubLoading] = useState(true);


    let history = useHistory();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

  
    const onChangeType = ({ target }) => {
        setSubLoading(true);
        const typeId = target.value;

        const newSubTypeData = initialSubType.filter(newSubType => 
            newSubType.tbl_typeID === typeId);
            setSubTypes(newSubTypeData);

            setSubLoading(false);
            setTypeId(typeId);
    }
        
    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
   
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
   
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
 
        }

        //types
        setLoading(true);
        const data = await getRoute('/types');
        setLoading(false);
        if (data) {
            setTypes(data.types);
        }

        //subtypes
        const assetSub = await getRoute('/subtypes');
        if (assetSub) {
            setSubTypes(assetSub.subtypes);

            setInitialSubType(assetSub.subtypes);
        }

        const facData = await getRoute('/facility');
        if (facData) {
            setFacilities(facData.facilities);
        }

        const supplData = await getRoute('/suppliers');
        if (supplData) {
          setSuppliers(supplData.suppliers);
        }

    }, []);
    //console.log(types);



    const saveAsset = (event)  =>  {
        event.preventDefault();

        if(desc.trim() == '' || desc.trim() == null ){

            alert("Description is required");
            return false;

        }else if(acquisition.trim() == '' || acquisition.trim() == null ){

            alert("Acquisition value is required");
            return false;

        }else if(code.trim() == '' || code.trim() == null ){

            alert("Serial number is required");
            return false;

        }else if(manufacture.trim() == '' || manufacture.trim() == null ){

            alert("Manufacturer is required");
            return false;

        }else if(year.trim() == '' || year.trim() == null ){

            alert("Year is required");
            return false;

        }else if(typeId.trim() == '' || typeId.trim() == null ){

            alert("Type of asset is required");
            return false;

        }else if(subtypeId.trim() == '' || subtypeId.trim() == null ){

            alert("Sub category of asset is required");
            return false;

        }else if(facility.trim() == '' || facility.trim() == null ){

            alert("Facility is required");
            return false;

        }else if(supplier.trim() == '' || supplier.trim() == null ){

            alert("Supplier is required");
            return false;

        }else if(residual.trim() == '' || residual.trim() == null ){

            alert("Residual value is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_assetDesc": desc,
                "tbl_assetAcquisValue": acquisition,

                "tbl_assetCode":randomString(8),
                "tbl_assetSN": code,

                "tbl_assetManufacture": manufacture,
                "tbl_assetBrand": brand,

                "tbl_assetModel": model,
                "tbl_assetNote": note,

                "tbl_assetResidualValue": residual,
                "tbl_subtypeID": subtypeId,

                "tbl_facilityID": facility,
                "tbl_assetPhoto": photo,

                "tbl_assetToken": randomString(8),
                "tbl_supplierID": supplier,

                "tbl_assetStatus": true,
                "tbl_assetActivity": 'NOT-ACTIVITY',
            }

            setLoading(true);


            axios.post('http://localhost:8080/api/v1/assets', postData)
        
            .then(res =>{

                console.log(res.data);
    
                if(res.data.user){

                    setLoading(false);

                    alert(desc+" has been added");
                    history.push("/registeredAssetReport");


                }else if(res.data.error){

                    setLoading(false);
                    alert("Process Failed");
                    history.push("/registeredAssetReport");
                    
                }else{

                    alert('Something went wrong');

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            }); 



        }
        

    }

    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">

            {loading ? 
              <center><img  src={"../../dist/img/loading.gif"} alt="Loading...." /></center> 
            : 
            <div className="addNewAsset">
     
        <h3 className="newUserTitle text-center text-white bg-info">Adding New Asset </h3>
                    <form className="newUserForm"  onSubmit={saveAsset} >
                    <div className="newUserItem">
                            <label>Asset Description</label>
                            <input onChange={(e) => setDesc(e.target.value)} type="text" placeholder="Laptop" name="assetDesc" id="assetDesc" />
                        </div>

                        <div className="newUserItem">
                            <label>Acquisition Value</label>
                            <input onChange={(e) => setAcquisition(e.target.value)} type="text" placeholder="value" name="acquisitionValue" id="acquisitionValue"
                              title="16 numbers"  />
                        </div>
                        
                        
                        <div className="newUserItem">
                            <label>Asset Code</label>
                            <input onChange={(e) => setCode(e.target.value)} type="text" placeholder="code" name="assetCode" id="assetCode" />
                        </div>
                        <div className="newUserItem">
                            <label>Manufacture</label>
                            <input onChange={(e) => setManufacture(e.target.value)} type="text" placeholder="Manufscture" name="assetManufacture" id="assetManufacture" />
                        </div>
                        <div className="newUserItem">
                            <label>Brand</label>
                            <input onChange={(e) => setBrand(e.target.value)} type="text" placeholder="Brand" name="assetBrand" id="assetBrand" />
                        </div>
                        <div className="newUserItem">
                            <label>Model</label>
                            <input onChange={(e) => setModel(e.target.value)} type="text" placeholder="Model" name="assetModel" id="assetModel" />
                        </div>
                        <div className="newUserItem">
                            <label>Note</label>
                            <input onChange={(e) => setNote(e.target.value)} type="text" placeholder="Note" name="assetNote" id="assetNote" />
                        </div> 
                        <div className="newUserItem">
                            <label>Received Year</label>
                            <input onChange={(e) => setYear(e.target.value)}  type="date" placeholder="Note" name="reaceivedYear" id="reaceivedYear" />
                        </div> 

                        <div className="newUserItem">
                        <label>Asset Type</label>
                        <select onChange={onChangeType}  name="assetType" id="assetType" className="newUserSelect">
                        <option value="">---Asset Type----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          types.map(type =>(
                            <option value={type.id} >
                             {type.tbl_typeName}
                            </option>
                        )) }

                        </select>
                        </div>

        
                        <div className="newUserItem"> 
                        <label>Asset Sub Type</label>
                        <select onChange={(e) => setSubtypeId(e.target.value)} name="assetSubType" id="assetSubType" className="newUserSelect">
                        <option value="">---Asset Sub Type----</option>

                        { subLoading ? <option value="">Loading ...</option> : 
                          subtypes.map(subtype =>(
                            <option value={subtype.id} >
                             {subtype.tbl_subtypeName}
                            </option>
                        )) }
                        </select>
                        </div>
        
                        <div className="newUserItem"> 
                        <label>Asset Facility</label>
                        <select onChange={(e) => setFacility(e.target.value)} name="assetFacility" id="assetFacility" className="newUserSelect">
                            <option value="">---Facility----</option>
                            { loading ? <option value="">Loading ...</option> : 

                              facilities.map(fac =>(
                                <option value={fac.id} >
                                  {fac.tbl_facilityName}
                                </option>
                            )) }
                        </select>
                        </div>


                        <div className="newUserItem"> 
                        <label>Supplier Name</label>
                        <select onChange={(e) => setSupplier(e.target.value)} name="assetSuppl" id="assetSuppl" className="newUserSelect">
                        <option value="">---Supplier----</option>
                            { loading ? <option value="">Loading ...</option> : 

                              suppliers.map(suppl =>(
                                <option value={suppl.id} >
                                  {suppl.tbl_supplierFullName} - {suppl.tbl_supplierTin}
                                </option>
                            )) }

                        </select>
                        </div>
        
                      
                        <div className="newUserItem">
                            <label>Photo </label> 
                            <input onChange={(e) => setPhoto(e.target.value)} type="file" placeholder="Etienne Ntambara" />
                        </div>

                        <div className="newUserItem">
                            <label>Residual Value</label>
                            <input onChange={(e) => setResidual(e.target.value)} type="number" placeholder="value" name="residualValue" id="residualValue" title="16 numbers"  />
                        </div>              
                        
                       {/* <div className="buttons">                */}
                       <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
                        {/* </div>  */}
                    </form>
                    
              
              </div>}
        </div>

        </div>
        </div>
    )
}
