import React from 'react'

// Delete

import { DataGrid } from '@mui/x-data-grid';

// Icons
import {allAssetRentedRows} from "../../../allData";
import { Link } from 'react-router-dom';

import { useState,useEffect } from "react";
import {getRoute} from '../../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ReturnedAssets() {

  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialReturns, setInitialSetReturns] = useState([]);

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const fetchData = async() => {
    setLoading(true);
    const data = await getRoute('/returned');
    setLoading(false);
    if (data) {
      setReturns(data.returnassets);
      setInitialSetReturns(data.returnassets);
    }     
  }

  useEffect(() => {

    if(localStorage.adminEmailToken){

      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
      setUserId(decoded.id);

    }

    fetchData();    
  }, []);

  //console.log(returns);


  const tableData = returns.map(   (returned, index) => {

    const { rentDetail } = returned;
    const { assetDetail }= rentDetail;

    //const { asset }= assetDetail;
    
    return {
        ...returned,
        assetName: assetDetail.tbl_assetDesc +" - "+assetDetail.tbl_assetCode,

        borrower: rentDetail.tbl_rent_assetBorrowBy,
        reason: rentDetail.tbl_rent_assetReason,


    }
  }); 

    // Display Returned Asset
// Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allAssetRentedRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
        
      
        { field: 'assetName', headerName: 'Asset Name', width: 230 },
               
       
        {
            field: 'borrower',
            headerName: 'Borrower',
            width: 180,
          },
          {
            field: 'createdAt',
            headerName: 'Returned On',
            width: 230,
          },

          {
            field: 'reason',
            headerName: 'Reason',
            width: 180,
          },


          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showReturnedAsset/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    
                   
                    </>
                )
            }
          },
         
      
      ];



// End Display Returned Asset
    return (

      <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
          <div className="viewEmployee">
       
        <div className="newUserTitle text-white bg-info">         
            <>               
                
                <label className="destroyedHeaderDisplay">All Asset's Returned</label>
                
                
                 </>
            
            
             </div>

             {loading ? 
                <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
              :
                <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
             }
                 
{/* Footer */}
<div className="footerHeight1">
               <hr />
                           
           </div>
           </div>
           </div>

           </div>
           </div>
    )
}
