import axios from 'axios';
import Config from '../config';

const baseURL = "http://localhost:8080/api/v1";
//const baseURL = "http://192.168.43.64:8080/api/v1";


export const RemoteServer = axios.create({ baseURL: baseURL, headers: Config.HTTP_HEADERS });

export const getRoute = async (endpoint, req) => {
    try {
        const response = await RemoteServer.get(endpoint, req);
        if (response) {
            return response.data;
        } else {
            return 'Something went wrong';
        }
    } catch (error) {
        throw error
    }
}

export const postRoute = async (endpoint, req) => {
    try {
        const response = await RemoteServer.post(endpoint, req);
        if (response) {
            return response.data;
        } else {
            return 'Something went wrong';
        }
    } catch (error) {
        throw error
    }
}




