import React from 'react'

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowMaintainanceRequest() {

    const [request, setRequest] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();
    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/maintenance/view/'+id);
        setLoading(false);
        if (data) {
            setRequest(data.maintRequestData);
            //return false;
        }

    }, []);

    if(!loading && !request){

        return <p>No request</p>; 
    }

    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="editNewAsset">

                         {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">
 
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
    <img src="https://www.zdnet.com/a/hub/i/r/2020/03/19/4d0e4e03-c980-43db-aa0f-2572aefcf89f/thumbnail/770x433/36d847f1d279f3a0cbaf29e1e9fc576f/surface-laptop-3-13-5-header.jpg" 
    alt="No Imange" className="userShowImg" />
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
        Name: {request.custodyreported.assetInfo.tbl_assetDesc}
    </span>
    <span className="userShowUserTitle">
      Code: {request.custodyreported.assetInfo.tbl_assetCode}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Asset Details</span>

    <div className="userShowInfo">
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
       SN: {request.custodyreported.assetInfo.tbl_assetSN}
    </span>
    </div>

    <div className="userShowInfo">
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
       Category: {request.subTypeData.tbl_subtypeName} - {request.typeData.tbl_typeName}
    </span>
    </div>




    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        Acquisition Value:  {request.custodyreported.assetInfo.tbl_assetAcquisValue}
    </span>
    </div>

  
    </div>
    </div>
    <div className="userUpdate">
        
    <form className="userUpdateForm">
    <div className="userUpdateLeft">

    <div className="userShowInfo">
        <h4 className="userShowTitle text-dark">Request Details</h4>
    </div>

    
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Problem:  {request.tbl_maintrequestDesc}
    </span>
    </div>

    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        Reported On: {request.createdAt}
    </span>
    </div> 
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Reported By: {request.custodyreported.reportedByData.tbl_staffFullName}
    </span>
    </div> 
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Received On: {request.custodyreported.tbl_custodassetFrom}
    </span>
    </div> 



    
  {/*<Link to="../maintainceRequestReport"> 
  <button className="btn btn-primary">Back</button>
  </Link>*/}

  <Link to={"../maintainance/"+id} > 
  <button className="btn btn-info">
      <i className="fa fa-plus"></i>
      Add Maintenance
  </button>
  </Link>


    </div>
    </form>
    </div>
    </div>


    {/* Footer Call */}
    <div className="footerHeight1">
               <hr />
                           
           </div>
    </div>}
            
        </div>

        </div>
        </div>
    )
}
