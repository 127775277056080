import React from 'react'
import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddSupplier() {

    const [sectors, setSectors] = useState([]);
    const [loading, setLoading] = useState(false);

    const [fullName, setFullName] = useState('');
    const [nid, setNid] = useState('');

    const [tin, setTin] = useState('');
    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('');

    const [sectorId, setSectorId] = useState('');
    let history = useHistory();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/sectors');
        setLoading(false);
        if (data) {
            setSectors(data.sectors);
        }
    }, []);


    const saveSupplier = (event)  =>  {
        event.preventDefault();

        if(fullName.trim() == '' || fullName.trim() == null ){

            alert("Full name is required");
            return false;

        }else if(nid.trim() == '' || nid.trim() == null ){

            alert("National ID is required");
            return false;

        }else if(nid.trim().length != 16  ){

            alert("National ID should be 16 numbers");
            return false;

        }else if(tin.trim() == '' || tin.trim() == null ){

            alert("TIN  is required");
            return false;

        }else if(tin.trim().length != 9  ){

            alert("TIN should be 9 numbers");
            return false;

        }else if(email.trim() == '' || email.trim() == null ){

            alert("Email is required");
            return false;

        }else if(phone.trim() == '' || phone.trim() == null ){

            alert("Phone is required");
            return false;

        }else if(sectorId.trim() == '' || sectorId.trim() == null ){

            alert("Sector of supplier is required");
            return false;

        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_supplierFullName": fullName,
                "tbl_supplierPhone": phone,

                "tbl_supplierTin": tin,
                "tbl_supplierNid": nid,

                "tbl_supplierEmail": email,
                "tbl_supplierTitle": title,

                "tbl_sectorID": sectorId,
                "tbl_supplierToken": randomString(5),

                "tbl_supplierStatus": true,
            }

            setLoading(true);

            axios.post('http://localhost:8080/api/v1/suppliers', postData)
        
            .then(res =>{
    
                if(res.data.user){

                    setLoading(false);

                    alert(fullName+" has been registered");
                    history.push("/suppliersReport");


                }else if(res.data.error){

                    setLoading(false);
                    alert("Process Failed");
                    history.push("/suppliersReport");
                    
                }else if(res.data.supplier){

                    setLoading(false);

                    alert(nid+" is already exist");
                    return false;

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            });



        }
        

    }

    return (


        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
        <div className=" addEmployee">
       
       <h1 className="newUserTitle bg-info text-center text-white">Adding Supplier .....</h1>
       <form className="newUserForm"  onSubmit={saveSupplier} >
      
          
           <div className="newUserItem">
               <label>Full Name</label>
               <input onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Etienne Ntambara" name="fullname" id="fullname" />
           </div>
           <div className="newUserItem">
               <label>NID (Identification)</label>
         <input onChange={(e) => setNid(e.target.value)} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
          placeholder="1199......"  name="nid" id="nid"  maxlength="16"  /> 
          

           </div>
           <div className="newUserItem">
        <label>TIN </label>
         <input onChange={(e) => setTin(e.target.value)} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
          placeholder="109912354"  name="tin" id="tin"  maxlength="9"/> 
          

           </div>
            
           <div className="newUserItem">
               <label>Email</label>
               <input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="email@gmail.com" name="email" id="email" />
           </div>
           <div className="newUserItem">
               <label>Phone</label>
           <input onChange={(e) => setPhone(e.target.value)} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}maxlength="10" 
         pattern=".{10}" title="10 numbers" placeholder="078 37 16 761"  name="phone" /> 
           </div>
         
           <div className="newUserItem">
               <label>Supplier Title</label>
               <input onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Title" name="supplierTitle" id="supplierTitle" />
           </div>
          
           <div className="newUserItem">
           <label>Sector</label>
           <select onChange={(e) => setSectorId(e.target.value)} name="supplierSector" id="supplierSector" className="newUserSelect">
               <option value="">----Select----</option>

               {loading ? <option value="">Loading ...</option> : 
                    sectors.map(sector =>(
                       <option value={sector.id} >
                           {sector.tbl_sectorName}
                       </option>
                    )) }
                        
               
           </select>
           </div>

             
      {/* <div className="buttons">    */}
                 
      <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
       {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
       {/* </div> */}

       
          
       </form>

          
       </div>
   </div>
   </div>
   </div>
    )
}
