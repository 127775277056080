import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowAssetRent() {

    const [returnInfo, setReturnInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/returned/view/'+id);
        setLoading(false);
        if (data) {
            setReturnInfo(data.returnassetData);
            //return false;
        }

    }, []);

    if(!loading && !returnInfo){

        return <p>No result found</p>; 
    }

    return (

    
        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
        <div className="editAssetType">

<div className="userContainer">
<div className="userShow">

<div className="userShowBottom">
<span className="userShowTitle font-weight-bold text-dark">Asset Returned Details</span>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle ">
    Asset Name:  
    {" "+returnInfo.rentDetail.assetDetail.tbl_assetDesc}
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
  Asset Code:  
    {" "+returnInfo.rentDetail.assetDetail.tbl_assetCode}
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Type: {" "+returnInfo.rentDetail.assetDetail.subtype.tbl_subtypeName}
      {" - "+returnInfo.rentDetail.typeData.tbl_typeName} 
</span>
</div>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Comment: {"  "+returnInfo.tbl_returnComment} 
</span>
</div>


</div>
</div>
<div className="userUpdate">
  
<form className="userUpdateForm">

<div className="userUpdateLeft">


<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
  Borrowed By: {"  "+returnInfo.rentDetail.tbl_rent_assetBorrowBy} 
</span>
</div>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Borrowed On: {"  "+returnInfo.rentDetail.createdAt} 
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Reason: {"  "+returnInfo.rentDetail.tbl_rent_assetReason} 
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
  Expected return date: {"  "+returnInfo.rentDetail.tbl_rent_assetExpReturn} 
</span>
</div>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
  Returned On: {"  "+returnInfo.createdAt} 
</span>
</div>

 <Link to="/allReturnedAssets">
 <button className="btn btn-info">Back</button>
 </Link>
</div>

</form>
</div>
</div>   



        {/* Footer */}
        <div className="footerHeight1">
           <hr />
                       
       </div>
        </div>}
    </div>

    </div>
    </div>
    )
}
