import React from 'react';
import {Close, DeleteOutline, Send} from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute} from '../../api/methods';

import { useHistory } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function Securitydash() {

    let history = useHistory();
    const [facilities, setFacilities] = useState([]);

    const [user, setUser] = useState('');  
    const [facility, setFacility] = useState('');

    const [loading, setLoading] = useState(false);
    const [activity, setActivity] = useState('');

    const [myActivity, setMyActivity] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/facility');
        setLoading(false);

        if (data) {
            setFacilities(data.facilities);
        }

        const decoded = jwt_decode(localStorage.adminEmailToken); 
        setUser(decoded.id);


        if(localStorage.ACTIVITY){

            setMyActivity(localStorage.ACTIVITY);
        }


    }, []);

    if(!loading && !facilities){

        return <p>No Result</p>; 
    }


    const saveActivity = (event)  =>  {
        event.preventDefault();

        if(activity.trim() == '' || activity.trim() == null ){

            alert("Activity is required");
            return false;

        }else if(facility.trim() == '' || facility.trim() == null ){

            alert("Facility is required");
            return false;

        }else{

            alert("Activity Saved");

            localStorage.setItem('ACTIVITY', activity);
            localStorage.setItem('FACILITY', facility);

            history.push("/DashboardSecurity"); 


        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">

           {myActivity != '' ? <center>
               <h3 className="newUserTitle bg-info text-white text-center" >{myActivity + " Assets"} </h3></center> :

            <div className="security">

                <h3 className="newUserTitle bg-info text-white text-center">Register/Check</h3>

                <form className="newUserForm" onSubmit={saveActivity}>
                <div className="newUserItem">
               <label>Facility</label>
               <select onChange={(e) => setFacility(e.target.value)} name="userFacility" id="userFacility" className="newUserSelect">
                   <option value="">----Select----</option>
                   { loading ? <option value="">Loading ...</option> : 

                    facilities.map(fac =>(
                        <option value={fac.id} >
                            {fac.tbl_facilityName}
                        </option>
                    )) }
               </select>
               </div>

               <div className="newUserItem">
               <label>Activity</label> 
               <select onChange={(e) => setActivity(e.target.value)} name="activity" id="activity" className="newUserSelect">
                   <option value="">----Select----</option>
                   <option value="checking">Checking</option>
                   {/*<option value="activity1">Register</option>*/}
                   
               </select>
               </div>
            
                
                
               
                
               {/* <div className="buttons">                */}
                <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
                {/* </div> */}
            </form>


            </div>}
            
        </div>

        </div>
        </div>
    );
}

