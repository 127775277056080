import React from 'react'

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function Maintainance() {

    const [request, setRequest] = useState(null);
    const [loading, setLoading] = useState(false);

    const [requestId, setRequestId] = useState('');
    const [desc, setDesc] = useState('');

    const [note, setNote] = useState('');
    const [cost, setCost] = useState('');

    const [maintainBy, setMaintainBy] = useState('');
    let history = useHistory();
  
    const { id } = useParams();
    const [assetDetailId, setAssetDetailId] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
    
            const decoded = jwt_decode(localStorage.adminEmailToken); 
    
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
    
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
    
        }

        setLoading(true);
        const data = await getRoute('/maintenance/view/'+id);
        setLoading(false);
        if (data) {
            setRequest(data.maintRequestData);
            //return false;

            setAssetDetailId(data.maintRequestData.custodyreported.assetInfo.id);
        }

        setRequestId(id);
        
        


    }, []);

    if(!loading && !request){

        return <p>No request</p>; 
    }

    const addMaintained = (event)  =>  {
        event.preventDefault();

        if(desc.trim() == '' || desc.trim() == null ){

            alert("Description is required");
            return false;

        }else if(requestId.trim() == '' || requestId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(note.trim() == '' || note.trim() == null ){

            alert("Note is required");
            return false;

        }else if(cost.trim() == '' || cost.trim() == null ){

            alert("Cost is required");
            return false;

        }else if(maintainBy.trim() == '' || maintainBy.trim() == null ){

            alert("Who maintain this?");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
        
            const postData = {

                "tbl_maintrequestID": requestId,
                "tbl_maintainanceDesc": desc,

                "tbl_maintainanceNotes": note,
                "tbl_maintainanceBy": maintainBy,

                "tbl_maintainanceCost": cost,
                "tbl_maintainanceToken": randomString(5),

                "tbl_maintainanceStatus": true,
                "assetDetailId": assetDetailId

            }

            setLoading(true);
            const data =  postRoute('/repaired',postData );
            
            if (data) {

                alert("Asset has been repaired");
                setLoading(false);

                history.push("/maintainceAssetReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }


    return (


        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="maintainance">

            <h3 className="newUserTitle text-center bg-info text-white">
                Add  
                <b className="text-warning" >
                    {" "+request.custodyreported.assetInfo.tbl_assetDesc} 
                    -  
                    {request.custodyreported.assetInfo.tbl_assetCode}
                </b> To Maintained Assets
            </h3>
            <form className="newUserForm" onSubmit={addMaintained}>

                <div className="newUserItem">
                    <label>Description</label>
                  <textarea onChange={(e) => setDesc(e.target.value)} name="maintainRequest" id="maintainRequest" cols="30" rows="10" className="newUserSelectDisplay" placeholder="Description"></textarea>
                </div>
                
                <div className="newUserItem">
                            <label>Notes</label>
                            <input onChange={(e) => setNote(e.target.value)} type="text" placeholder="Note" name="assetNote" id="assetNote" />
                        </div>
                        <div className="newUserItem">
                            <label>Maintanance Cost</label>
                            <input onChange={(e) => setCost(e.target.value)} type="text" placeholder="cost" name="maintainanceCost" id="maintainanceCost" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                        </div>
                <div className="newUserItem">
                            <label>Maintained By</label>
                            <input onChange={(e) => setMaintainBy(e.target.value)}  type="text" placeholder="Maintained By...." name="assetDesc" id="assetDesc" />
                        </div>
            
                
                
               
                <button className="newUserButton bg-info"><Send className="text-warning"/> Maintain</button>

            </form>



            </div>}
            
        </div>

        </div>
        </div>
    )
}
