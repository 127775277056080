import React, { Component } from 'react'
export default class MovementReport extends Component {
    render() {
        return (
            <div className="content-wrapper bg-white">
                <div className="movementAsset">Movement Report</div>
                
                
            </div>
        )
    }
}
