import React from 'react'

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowNewAsset() {

    const [asset, setAsset] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();
    const [custodLoading, setCustodLoading] = useState(false);

    const [custodAssets, setCustodyAssets] = useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 
  
        setUserEmail(decoded.tbl_staffEmail);
        setUserRole(decoded.staffFoundRole.name);
  
        setUserFullName(decoded.tbl_staffFullName);
        setUserId(decoded.id);
  
      }
        setLoading(true);
        const data = await getRoute('/assets/asset/'+id);
        setLoading(false);
        if (data) {
            setAsset(data.asset);
            //return false;
        }

        //custody assets
        setCustodLoading(true);
        const custoodyData = await getRoute('/custody-assets/related/'+id);
        setCustodLoading(false);
        if (custoodyData) {
          setCustodyAssets(custoodyData.custodAssets);
        }


    }, []);

    //console.log(asset);

    // custody assets
    const tableData = custodAssets.map((custodAsset, index) => {
      const { asset, staff, user, workingLocation } = custodAsset;
      
      return {
          ...custodAsset,
          assetName: asset.tbl_assetDesc + (custodAsset.tbl_custodassetStatus== true ? " (Still there)": "(Moved)"),

          assetCode: asset.tbl_assetCode,
          staffName: staff.tbl_staffFullName,

          staffNid: staff.tbl_staffNID,
          userFullName: user.tbl_staffFullName,

          workInfo : workingLocation.tbl_facilityName,
          workCode : workingLocation.tbl_facilityCode,

      }
    });


    //columns

    const columns = [
      { field: 'id', headerName: 'No', width: 20 },
     
      {
          field: 'staffName',
          headerName: 'Employee Name',
          width: 180,
        },
        {
          field: 'workInfo',
          headerName: 'Facility',
          width: 180,
        },
        {
          field: 'workCode',
          headerName: 'Facility Code',
          width: 180,
        },

        {
          field: 'tbl_custodassetFrom',
          headerName: 'From',
          width: 180,
        },

        {
          field: 'tbl_custodassetTo',
          headerName: 'To',
          width: 180,
        },

    
    ];


    if(!loading && !asset){

      return <p>No asset</p>; 
    }

    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="editNewAsset">

        <div className="userTitleContainer">
 
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
    <img  src={"../dist/img/"+asset.tbl_assetPhoto} alt="No Imange" className="userShowImg" />
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
       {asset.tbl_assetDesc}
    </span>
    <span className="userShowUserTitle">
        {asset.facility.tbl_facilityName}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Asset Details</span>
    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
       Acquistion Value: {" "+new Intl.NumberFormat('en-US', 
      {style: 'currency',currency: 'RWF',})
      .format(asset.tbl_assetAcquisValue) }
    </span>
    </div>
    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Asset Code: {asset.tbl_assetCode}
    </span>
    </div>
    

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Brand: {asset.tbl_assetBrand}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Model: {asset.tbl_assetModel}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       SN: {asset.tbl_assetSN}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       LifeSpan: {asset.assetTypeInfo.tbl_typeLifeSpan+" Years"}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Annually Depreciation: { " "+new Intl.NumberFormat('en-US', 
      {style: 'currency',currency: 'RWF',})
      .format(asset.tbl_assetAcquisValue / asset.assetTypeInfo.tbl_typeLifeSpan) }
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Total Depreciation: {  
        " "+new Intl.NumberFormat('en-US', 
        {style: 'currency',currency: 'RWF',})
        .format(
        (new Date().getFullYear()  - new Date(asset.createdAt).getFullYear()) * 
        (asset.tbl_assetAcquisValue / asset.assetTypeInfo.tbl_typeLifeSpan))
      }
    </span>
    </div>

    </div>
    </div>
    <div className="userUpdate">
        
    <form className="userUpdateForm">
    <div className="userUpdateLeft">
    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        Status: {asset.tbl_assetStatus == true ? "Working": "Not Working" }
    </span>
    </div>
    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        Received On: 
        {" "+new Date(asset.createdAt).getDate()+
          "/"+(new Date(asset.createdAt).getMonth()+1)+
          "/"+new Date(asset.createdAt).getFullYear()+""
        }
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Category: {asset.subtype.tbl_subtypeName}
    </span>
    </div> 

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Manufacturer: {asset.tbl_assetManufacture}
    </span>
    </div> 

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Supplier: {asset.supplier.tbl_supplierFullName}
    </span>
    </div> 

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Supplier Title: {asset.supplier.tbl_supplierTitle}
    </span>
    </div> 

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Residual value: {" "+new Intl.NumberFormat('en-US', {style: 'currency',currency: 'RWF',}).format(Number(asset.tbl_assetResidualValue))}
    </span>
    </div> 

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Note: {asset.tbl_assetNote}
    </span>
    </div> 

    { asset.tbl_assetActivity == "NOT-ACTIVITY"  ? 
    <div className="row">
      {/*
      <div className="col-md-6">
        <Link to="/registeredAssetReport"> 
          <button className="btn btn-info">Back</button>
        </Link>
      </div>  */ }

      <div className="col-md-6">
        <Link to={"../addAssetRent/"+id} > 
          <button className="btn btn-primary">
            <i className="fa fa-plus"></i>
            Add To Rent
          </button>
        </Link>
      </div>

      <div className="col-md-6">
        <Link to={"../custodianAsset/"+id} > 
          <button className="btn btn-success">
            <i className="fa fa-plus"></i>
            Assign To Custodian
          </button>
        </Link>
      </div>

    </div>
    : null }






  

    </div>
    </form>
    </div>
    </div>



    <center>
      <h2>All facilities where  
        <b>
        {" "+asset.tbl_assetDesc}
        </b>  have been used
      </h2>
    </center>

    {custodLoading  ? 
      <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
        :
      <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
    }

  
    <div className="footerHeight1">
               <hr />
                           
           </div>
    </div> }
            
        </div>

        </div>
        </div>
    )
}
