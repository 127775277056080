import React from 'react'

import { Send, Close } from "@material-ui/icons";
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddAudit() {

    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState([]);

    const [assets, setAssets] = useState([]);
    const [types, setTypes] = useState([]);
    
    const [conditions, setConditions] = useState([]);
    let history = useHistory();

    const [custodAssets, setCustodyAssets] = useState([]);

    const [staffId, setStaffId] = useState('');
    const [typeId, setTypeId] = useState('');

    const [assetId, setAssetId] = useState('');
    const [condition, setCondition] = useState('');

    const [service, setService] = useState('');
    const [validity, setValidity] = useState('');

    const [reason, setReason] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
    
            const decoded = jwt_decode(localStorage.adminEmailToken); 
    
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
    
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
    
        }

        setLoading(true);
        const data = await getRoute('/staffs');
        setLoading(false);
        if (data) {
            setStaffs(data.staffs);
        }
        
        //assets
        const assetsData = await getRoute('/assets');
        if (assetsData) {
            setAssets(assetsData.assets);
        }

        const typeData = await getRoute('/types');
        if (typeData) {
            setTypes(typeData.types);
        }

        //conditions

        const conditionData = await getRoute('/conditions');
        if (conditionData) {
            setConditions(conditionData.conditions);
        }

        const custodData = await getRoute('/custody-assets');
        if (custodData) {
          setCustodyAssets(custodData.custodAssets);
        }

    }, []);



    const saveAudit = (event)  =>  {
        event.preventDefault();

        if(staffId.trim() == '' || staffId.trim() == null ){

            alert("Employee is required");
            return false;

        }else if(typeId.trim() == '' || typeId.trim() == null ){

            alert("Type of asset is required");
            return false;

        }else if(assetId.trim() == '' || assetId.trim() == null ){

            alert("Asset is required");
            return false;

        }else if(condition.trim() == '' || condition.trim() == null ){

            alert("Condition is required");
            return false;

        }else if(service.trim() == '' || service.trim() == null ){

            alert("Service is required");
            return false;

        }else if(validity.trim() == '' || validity.trim() == null ){

            alert("Validity is required");
            return false;

        }else if(reason.trim() == '' || reason.trim() == null ){

            alert("Reason is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_custodassetID": assetId,
                "tbl_auditComment": reason,

                "tbl_conditionID": condition,
                "tbl_auditServiceStatus": service,

                "tbl_auditIsValid": validity,
                "tbl_auditToken": randomString(5),

                "tbl_auditStatus": true,
            }

            setLoading(true);
            const data =  postRoute('/audits',postData );
            
            if (data) {

                alert("Audited asset has been saved");
                setLoading(false);

                history.push("/auditReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="addNewAsset">

    <h3 className="newUserTitle text-center text-white bg-info">Record Audit </h3>
                <form className="newUserForm"  onSubmit={saveAudit} >

                <div className="newUserItem">
                    <label>Custodian Name</label>
                    <select onChange={(e) => setStaffId(e.target.value)} name="custodianName" id="custodianName" className="newUserSelect">
                    <option value="">---Custodian Name----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          staffs.map(staff =>(
                            <option value={staff.id} >
                             {staff.tbl_staffFullName} - {staff.tbl_staffNID}
                            </option>
                        )) }
                    </select>
                    </div>

                    <div className="newUserItem">
                    <label>Custodian NID</label>
                    <select name="custodianNid" id="custodianNid" className="newUserSelect">
                    <option value="">---Custodian NID----</option>
                        <option value="custNID">11994800310658</option>
                        <option value="custNID">11994800310658</option>
                    </select>
                    </div>

                    <div className="newUserItem">
                    <label>Asset Type</label>
                    <select onChange={(e) => setTypeId(e.target.value)} name="cassetType" id="cassetType" className="newUserSelect">
                    <option value="">---Asset Type----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          types.map(type =>(
                            <option value={type.id} >
                             {type.tbl_typeName}
                            </option>
                        )) }
                    </select>
                    </div>

                    <div className="newUserItem">
                    <label>Asset Name</label>
                    <select onChange={(e) => setAssetId(e.target.value)} name="cassetName" id="cassetName" className="newUserSelect">
                    <option value="">---Asset Name----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          custodAssets.map(custAsset =>(
                            <option value={custAsset.id} >
                             {custAsset.tbl_custodassetFrom} - {custAsset.tbl_custodassetTo}
                            </option>
                        )) }
                    </select>
                    </div>
                
                    <div className="newUserItem">
                    <label>Condition</label>
                    <select onChange={(e) => setCondition(e.target.value)} name="auditCondition" id="auditCondition" className="newUserSelect">
                    <option value="">---Condition----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          conditions.map(condition =>(
                            <option value={condition.id} >
                             {condition.tbl_conditionName}
                            </option>
                        )) }
                    </select>
                    </div>
                    <div className="newUserItem">
                    <label>Service Status</label>
                    <select onChange={(e) => setService(e.target.value)}  name="serviceStatus" id="serviceStatus" className="newUserSelect">
                    <option value="">---Service Status----</option>
                        <option value="IN-SERVICE">IN-SERVICE</option>
                        <option value="NOT-IN-SERVICE">NOT IN-SERVICE</option>
                    </select>
                    </div>
                    <div className="newUserItem">
                    <label>Validity</label>
                    <select onChange={(e) => setValidity(e.target.value)}  name="auditValidity" id="auditValidity" className="newUserSelect">
                    <option value="">---Validity---</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      
                    </select>
                    </div>
                    
                    <div className="newUserItem">
                        <label>Reason (Comment)</label>
                        <textarea onChange={(e) => setReason(e.target.value)} name="auditReason" id="auditReason" cols="30" rows="10" className="newUserSelect"></textarea>
                    </div> 
                   
                   <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
           
                </form>
                
        
          </div>
    </div>

    </div>
    </div>

    )
}
