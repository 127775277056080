import React from 'react'

// Delete
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allAuditRows} from "../../allData";

import { useState,useEffect } from "react";
import {getRoute} from '../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewAudit() {

  const [audits, setAudits] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialAudits, setInitialAudits] = useState([]);

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const fetchData = async() => {

    if(localStorage.adminEmailToken){
    
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    }

    setLoading(true);
    const data = await getRoute('/audits');
    setLoading(false);
    if (data) {
      setAudits(data.audits);
      setInitialAudits(data.audits);
    }     
  }

  useEffect(() => {
    fetchData();    
  }, []);

  console.log(audits);


  const tableData = audits.map(   (audit, index) => {

    const { employeeAsset } = audit;
    const { assetInfo }= employeeAsset;

    const { ownedByData }= employeeAsset;
    const { conditionData } = audit;

    const { subTypeData } = audit;
    const { typeData } = audit;
    
    return {
        ...audit,
        Name: employeeAsset.tbl_custodassetFrom,

        assetName: assetInfo.tbl_assetDesc,

        assetCode: assetInfo.tbl_assetCode,
        assetAcq: assetInfo.tbl_assetAcquisValue,

        assetResidual: assetInfo.tbl_assetResidualValue,
        custodianName: ownedByData.tbl_staffFullName,

        custodianNid: ownedByData.tbl_staffNID,
        conditionName: conditionData.tbl_conditionName,

        validityName : audit.tbl_auditIsValid ? "Yes" : "No", 
        categoryName: subTypeData.tbl_subtypeName +' - '+typeData.tbl_typeName,

    }
  });

            // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allAuditRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
      
        { field: 'custodianNid', headerName: 'Custodian NID', width: 230 },
       
        {
            field: 'custodianName',
            headerName: 'Custodian Name',
            width: 230,
          },
          {
            field: 'assetName',
            headerName: 'Asset Name',
            width: 230,
          },

          {
            field: 'assetCode',
            headerName: 'Asset Code',
            width: 230,
          },

          {
            field: 'categoryName',
            headerName: 'Asset Type',
            width: 230,
          },


          {
            field: 'conditionName',
            headerName: 'Condition',
            width: 230,
          },
          {
            field: 'tbl_auditServiceStatus',
            headerName: 'Service Status',
            width: 230,
          },
          {
            field: 'validityName',
            headerName: 'Validity',
            width: 230,
          },
          {
            field: 'tbl_auditComment',
            headerName: 'Audit Comment',
            width: 250,
          },
          {
            field: 'createdAt',
            headerName: 'Date',
            width: 250,
          },
       
        
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>

                    <Link to={"/showAudit/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" ></i>
                        </button>
                    </Link>
                    <Link to={"/editAudit/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" ></i>
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];



// End Display Data
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
           {/* DISPLAYING ASSET */}
         
           <div className="newUserTitle text-white bg-info">         
           <>               
               
               <label className="destroyedHeaderDisplay"> Audit</label>
              
               </>
           
           
            </div>

            {loading ? 
              <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
              <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
            }
                 {/* Footer Call */}
                 <div className="footerHeight1">
              <hr />
                          
          </div>
          </div>
        </div>

        </div>
        </div>
    )
}
