import React from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allDestroyedAssetRows} from "../../../allData";
// Delete
import { useState,useEffect } from "react";
import {getRoute} from '../../../api/methods';

import axios from 'axios';
import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ViewMaintainance() {

  const [maintrequets, setMaintrequets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialsetMaintrequets, setInitialsetMaintrequets] = useState([]);

  const [currentAsset, setCurrentAsset] = useState([]);


  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const fetchData = async() => {

    setLoading(true);
    const data = await getRoute('/maintenance');
    setLoading(false);
    if (data) {
      setMaintrequets(data.maintrequets);
      setInitialsetMaintrequets(data.maintrequets);
    }     
  }

  useEffect(() => {

    if(localStorage.adminEmailToken){

      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
      setUserId(decoded.id);

    }

    fetchData();    
  }, []);

  console.log(maintrequets);


  const tableData = maintrequets.map(   (maintrequet, index) => {

    const { custodyreported } = maintrequet;
    const { assetInfo }= custodyreported;

    const { reportedByData, supplier, ownedByData }= custodyreported;
    
    return {
        ...maintrequet,
        Name: custodyreported.tbl_custodassetFrom,

        assetName: assetInfo.tbl_assetDesc,

        assetCode: assetInfo.tbl_assetCode,
        assetAcq: assetInfo.tbl_assetAcquisValue,

        assetResidual: assetInfo.tbl_assetResidualValue,
        reportedUser: reportedByData.tbl_staffFullName,

        supplierDetail: supplier.tbl_supplierFullName,
        ownedByDetail : ownedByData.tbl_staffFullName,

        arrivalTime : new Date(maintrequet.createdAt).getDate()+
        "/"+(new Date(maintrequet.createdAt).getMonth()+1)+
        "/"+new Date(maintrequet.createdAt).getFullYear(), 

    }
  });













                   
// Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allDestroyedAssetRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
        { field: 'assetCode', headerName: 'Asset Code', width: 180 },

        {
          field: 'ownedByDetail',
          headerName: 'Reqst Staff',
          width: 180,
        },

        {
          field: 'supplierDetail',
          headerName: 'Supplier',
          width: 180,
        },

        {
          field: 'tbl_maintrequestDesc',
          headerName: 'Required Maint',
          width: 180,
        },
        {
          field: 'reportedUser',
          headerName: 'Reported By',
          width: 180,
        },

          {
            field: 'arrivalTime',
            headerName: 'Reported On',
            width: 150,
          },
                  
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showMaintainanceRequest/"+params.row.id}>
                    <button className="userListEdit bg-info">
                       <i className="fas fa-eye text-white"/>
                        </button>
                    </Link>
                    <Link to={"/maintainance/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        Repair Asset
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                                    
                    </>
                )
            }
          },
      
      ];



// End Display Data
    return (

      <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  
        <div className="content-wrapper bg-white">
        <div className="viewEmployee">
       {/* DISPLAYING ASSET */}
       <div className="newUserTitle text-white bg-info">         
       <>               
           
           <label className="destroyedHeaderDisplay"> Asset's Maintainance Request</label>
           
           
           </>
       
       
        </div>

        {loading ? 
          <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
          <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
        }

             {/* Footer Call */}
             <div className="footerHeight1">
          <hr />
                      
      </div>
      </div>
      </div>
      </div>

      </div>
    )
}
