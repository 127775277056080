import React from 'react'

// Delete
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";
import {allMovementRows} from "../../allData";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewAssetMovement() {


  const [rentassets, setRentAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(async () => {

    if(localStorage.adminEmailToken){
    
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    }
      setLoading(true);
      const data = await getRoute('/rent');
      setLoading(false);
      if (data) {
        setRentAssets(data.rentassets);
      }
  }, []);
  console.log(rentassets)

          // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allMovementRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };


    const tableData = rentassets.map(   (rentasset, index) => {

      const { assetTobeRent, rentuser } = rentasset;
      const { subTypeData, typeData }= assetTobeRent;
      
      return {
          ...rentasset,
          assetName: assetTobeRent.tbl_assetDesc,
  
          assetCode: assetTobeRent.tbl_assetCode,
          assetAcq: assetTobeRent.tbl_assetAcquisValue,
  
          assetResidual: assetTobeRent.tbl_assetResidualValue,
          confirmedBy: rentuser.tbl_staffFullName,

          categoryName: subTypeData.tbl_subtypeName +' - '+typeData.tbl_typeName,
  
      }
    });



    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
       
        {
            field: 'tbl_rent_assetBorrowBy',
            headerName: 'Borrower',
            width: 230,
          },
          {
            field: 'assetName',
            headerName: 'Asset Name',
            width: 230,
          },

          {
            field: 'categoryName',
            headerName: 'Asset Type',
            width: 230,
          },

          {
            field: 'tbl_rent_assetFee',
            headerName: 'Fees',
            width: 250,
          },
          {
            field: 'createdAt',
            headerName: 'Date',
            width: 250,
          },
          
          {
            field: 'confirmedBy',
            headerName: 'Recorded By',
            width: 180,
          },
          
        
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showAssetRent/"+params.row.id}>
                      <button className="return bg-info">
                        <i  className="fas fa-eye text-white"/>
                      </button>
                    </Link>
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];



// End Display Data
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
          {/* DISPLAYING ASSET */}

          <div className="newUserTitle text-white bg-info">         
          <>               
              
              <label className="destroyedHeaderDisplay">Rent Assets</label>
              
              </>
          
          
           </div>

           {loading ? 
              <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
              <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
            }
                {/* Footer Call */}
                <div className="footerHeight1">
             <hr />
                         
         </div>            
        </div>
        </div>

        </div>
        </div>
    )
}
