import React from 'react'
import {useState,useEffect} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import {getRoute} from '../../api/methods';

export default function Excel() {
  const [custodAssets, setCustodyAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [departs, setDeparts] = useState([]);
  const [departLoading, setDepartLoading] = useState(false);

  const [logRole, setLogRole] = useState(false);
  const [initialCustodAssets, setInitialCustodyAssets] = useState([]);

  const [currentDepart, setCurrentDepart] = useState([]);
  const [loadCurrDepart, setLoadCurrDepart] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const onChangeDepartment = ({ target }) => {
    const departId = target.value;

    if(departId == null){

      setCustodyAssets(initialCustodAssets);
      setLoadCurrDepart(" All Assets used in all departments ");
      
    }else{

      const newDeparts = departs.filter(newDepart => 
        newDepart.id === departId);
        setCurrentDepart(newDeparts);

        setLoadCurrDepart(" All Assets used in this department ");

      const newCustodAssets = initialCustodAssets.filter(newCustodAsset => 
        newCustodAsset.staff.tbl_departmentID === departId);
        setCustodyAssets(newCustodAssets);

    }

    
  }

  useEffect(async () => {

    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    } 

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 

        if(decoded.staffFoundRole.name == "custodian" 
          || decoded.staffFoundRole.name == "IT" || 
          decoded.staffFoundRole.name == "HR" || 
          decoded.staffFoundRole.name == "lab-manager"  
        ){

          setLoading(true);
          const data = await getRoute('/custody-assets/emp/'+decoded.id);
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);
          }
        
        }else if(decoded.staffFoundRole.name == "asset-manager"){

          setLogRole(decoded.staffFoundRole.name);
          setLoading(true);
          
          const data = await getRoute('/custody-assets');
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);

            setInitialCustodyAssets(data.custodAssets);
          }

          setDepartLoading(true);
          const departData = await getRoute('/departs');
          setDepartLoading(false);
          if (departData) {
              setDeparts(departData.departs);
          }



        }

        setLoadCurrDepart(" All Assets used in all departments  ");



      }


  }, []);

    console.log(custodAssets);

    let totalDepr;
    let calcPrior;


    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
        <div className="viewEmployee container">
        <h3 className="mt-3 text-success">
            <center>Export department assets </center>
        </h3>

        <div className="newUserTitle text-white bg-info">         
        <>               
            
            <label className="destroyedHeaderDisplay">  
                <b>{loadCurrDepart}</b>
            </label>
            
            {logRole == "asset-manager" ? 
              <select onChange={onChangeDepartment} name="assetType" id="assetSubType" className="newUserSelectDisplay">
                <option value="">---Department----</option>
                {departLoading ? <option value="">---Loading----</option> :
                    departs.map(depart =>(
                      <option value={depart.id} >
                        {depart.tbl_departmentName}
                      </option>
                    )) 
                }
              </select>
            : null }

            </>
          </div>
        <div className="row mt-4">
        <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-info mb-3 pull-right"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download"/>
           <table className="table" id="table-to-xls">
            <thead className="thead-info">

            <tr>
                <th>Description</th>
                <th>Code</th>

                <th>Acqu Value</th>
                <th>Acqu Year</th>

                <th>Depr</th>
                <th>Prior Depr</th>

                <th>Acc Depr</th>
                <th>Net Value</th>
            </tr>
            </thead>

            {loading ? 
                <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <tbody>
           
                 {
                 
                 custodAssets.map((res)=>

                    <tr>
                    <td>{res.asset.tbl_assetDesc}</td>
                    <td>{res.asset.tbl_assetCode}</td>

                    <td>
                      {new Intl.NumberFormat('en-US', 
                        {style: 'currency',currency: 'RWF',}).format(res.asset.tbl_assetAcquisValue)}
                    </td>

                    <td>{new Date(res.asset.createdAt).getFullYear()}</td>
                    <td>
                      {new Intl.NumberFormat('en-US', 
                        {style: 'currency',currency: 'RWF',}).format(
                          (new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear()) * 
                          (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan))
                      }
                    </td>

                    <td>

                      {new Intl.NumberFormat('en-US', 
                        {style: 'currency',currency: 'RWF',}).format((new Date().getFullYear()  === new Date(res.asset.createdAt).getFullYear()) ?  
                      0  
                      
                      :

                      (new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear() -1) * 
                      (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan))
                      
                      }

                    </td>

                    <td>
                      {new Intl.NumberFormat('en-US', 
                        {style: 'currency',currency: 'RWF',}).format(
                          ((new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear()) * 
                        (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan)) + 

                       ((new Date().getFullYear()  === new Date(res.asset.createdAt).getFullYear()) ?  
                       0  
                       
                       :
 
                       (new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear() -1) * 
                       (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan)))
                      }
                    </td>

                    <td>
                      {new Intl.NumberFormat('en-US', 
                        {style: 'currency',currency: 'RWF',}).format(
                          res.asset.tbl_assetAcquisValue -  
                      (((new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear()) * 
                      (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan)) + 

                     ((new Date().getFullYear()  === new Date(res.asset.createdAt).getFullYear()) ?  
                     0  
                     
                     :

                     (new Date().getFullYear()  - new Date(res.asset.createdAt).getFullYear() -1) * 
                     (res.asset.tbl_assetAcquisValue / res.assetTypeInfo.tbl_typeLifeSpan))))
                      }
                    </td>

                    


                    </tr>
                  )}   
               
            </tbody>
            }   
        </table>
     </div>
    </div>
    </div>

    </div>
    </div>
    )
}
