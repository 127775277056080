import React from 'react'
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";
// Icons

import {Close, DeleteOutline, Send} from "@material-ui/icons";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddDepartment() {

    const [departName, setDepartName] = useState('');
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        }

    }, []);

    const saveDepartment = (event)  =>  {
        event.preventDefault();

        if(departName.trim() == '' || departName.trim() == null ){

            alert("Name is required");
            return false;

        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_departmentName": departName,
                "tbl_departmentToken": randomString(5),
                "tbl_departmentStatus": true,
            }

            setLoading(true);

            axios.post('http://localhost:8080/api/v1/departs', postData)
        
            .then(res =>{


                console.log(res.data);
    
                if(res.data.user){

                    setLoading(false);

                    alert(departName+" has been registered");
                    history.push("/departmentreport");


                }else if(res.data.error){

                    setLoading(false);
                    alert("Process Failed");
                    history.push("/departmentreport");
                    
                }else if(res.data.depart){

                    setLoading(false);

                    alert(departName+" is already exist");
                    return false;

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            });    


        }
        

    }


    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
        <div className="addAssetType">


            <h3 className="newUserTitle text-center bg-info text-white">Adding  Department </h3>
            <form className="newUserForm" onSubmit={saveDepartment} >
            <div className="newUserItem">
                    <label>Department Name</label>
                    <input onChange={(e) => setDepartName(e.target.value)} type="text"  placeholder="Finance" name="departName" id="departName" required />
                </div>
            
                
                
               
                
               {/* <div className="buttons">                */}
                <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
                {/* </div> */}
            </form>
           
           
            </div>
        </div>

        </div>
        </div>
    )
}
