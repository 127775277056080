import React from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allMaintainanceAssetRows} from "../../../allData";

// Delete
import { useState,useEffect } from "react";
import {getRoute} from '../../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function AllMaintainedAssets() {

  const [repairs, setRepairs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialsetRepairs, setInitialsetRepairs] = useState([]);

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const fetchData = async() => {

    if(localStorage.adminEmailToken){

      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
      setUserId(decoded.id);

    }

    setLoading(true);
    const data = await getRoute('/repaired');
    setLoading(false);
    if (data) {
      setRepairs(data.repair);
      setInitialsetRepairs(data.repair);
    }     
  }

  useEffect(() => {
    fetchData();    
  }, []);

  console.log(repairs);

  
  const tableData = repairs.map(   (repair, index) => {

    const { request } = repair;
    const { custodyreported }= request;

    const { asset }= custodyreported;
    
    return {
        ...repair,
        Name: custodyreported.tbl_custodassetFrom,

        assetName: asset.tbl_assetCode,
        assetAcq: asset.tbl_assetAcquisValue,

        assetResidual: asset.tbl_assetResidualValue,
        assetSN: asset.tbl_assetSN,

        problem: request.tbl_maintrequestDesc,

        item: request.assetSubtypeInfo.tbl_subtypeName + " - "
        +request.assetSubtypeInfo.type.tbl_typeName,

        facilityName: request.facility.tbl_facilityName,
        departName: request.depart.tbl_departmentName,

        registerDate : new Date(repair.createdAt).getDate()+
        "/"+(new Date(repair.createdAt).getMonth()+1)+
        "/"+new Date(repair.createdAt).getFullYear(),

        recorderdBy: custodyreported.user.tbl_staffFullName,


    }
  }); 


                  
// Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allMaintainanceAssetRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 50 },
        { field: 'assetName', headerName: 'Asset Code', width: 180 },

        { field: 'item', headerName: 'Item', width: 180 },
        { field: 'facilityName', headerName: 'Facility', width: 150 },

        { field: 'departName', headerName: 'Dpt', width: 150 },
        {
          field: 'tbl_maintainanceDesc',
          headerName: 'Maint Desc',
          width: 180,
      },

        { field: 'tbl_maintainanceBy', headerName: 'Maint By', width: 150 },

          {
            field: 'registerDate',
            headerName: 'Date',
            width: 120,
          },

          {
            field: 'recorderdBy',
            headerName: 'Record By',
            width: 150,
          },
          

          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showAllMaintainedAssets/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    
                   
                    </>
                )
            }
          },
          
     
      
      ];



// End Display Data
    return (

      <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />

        <div className="content-wrapper bg-white">
        <div className="viewEmployee">
                          {/* DISPLAYING ASSET */}
                          <div className="newUserTitle text-white bg-info">         
                          <>               
                              
                              <label className="destroyedHeaderDisplay"> Asset's Maintained</label>
                              
                              </>
                          
                          
                           </div>

                            {loading ? 
                              <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
                                :
                              <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
                            }
                                {/* Footer Call */}
                                <div className="footerHeight1">
                             <hr />
                                         
                         </div>
                         </div>
                         </div>

                        </div>

                        </div>
    )
}
