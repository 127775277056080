import React from 'react'
// Delete
import { useState, useEffect } from "react";
import {getRoute} from '../../../api/methods';

import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allAssetRows} from "../../../allData";
import { Link } from 'react-router-dom';

import { Send, Close } from "@material-ui/icons";

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ViewAssets() {

    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');
  
    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/assets');
        setLoading(false);
        if (data) {
            setAssets(data.assets);
        }
    }, []);
    console.log(assets);

    // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allAssetRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    const tableData = assets.map((asset, index) => {
        const { subtype, facility, supplier } = asset;
        
        return {
            ...asset,
            subtypeName: subtype.tbl_subtypeName,

            facilityName: facility.tbl_facilityName,
            supplierName: supplier.tbl_supplierFullName,

        }
    });

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },

        { field: 'tbl_assetDesc', headerName: 'Description', width: 250 },
        // Image call
        
        { field: 'tbl_assetAcquisValue', headerName: 'Acquisition_Value', width: 230 },
       
        {
            field: 'tbl_assetCode',
            headerName: 'Code',
            width: 180,
          },
          {
            field: 'tbl_assetNote',
            headerName: 'Note',
            width: 120,
          },
          {
            field: 'createdAt',
            headerName: 'Year',
            width: 120,
          },
          {
            field: 'subtypeName',
            headerName: 'Sub Type',
            width: 150,
          },
          {
            field: 'facilityName',
            headerName: 'Facility',
            width: 150,
          },
          {
            field: 'supplierName',
            headerName: 'Supplier',
            width: 200,
          },
        
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showNewAsset/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    <Link to={"/editNewAsset/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" />
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];



// End Display Data
    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
        <div className="viewEmployee">
         {/* DISPLAYING ASSET */}
            
         <div className="newUserTitle text-white bg-info">         
            <>               
                
                <label className="destroyedHeaderDisplay"> All Assets</label>
                
                
                 
                </>
            
            
             </div>
             {loading ? 
                <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
                :
                <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
             }

                  {/* Footer Call */}
                  <div className="footerHeight1">
               <hr />
                           
           </div>
        </div>
        </div>

        </div>
        </div>
    )
}
