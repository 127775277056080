import React from 'react'
// Delete
import { useState, useEffect } from "react";
import {getRoute} from '../../../api/methods';

import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {asetSubTypeRows} from "../../../allData";
import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ViewAssetSubType() {

    const [subtypes, setSubTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');
  
    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/subtypes');
        setLoading(false);
        if (data) {
            setSubTypes(data.subtypes);
        }
    }, []);

    const tableData = subtypes.map((subtype, index) => {
        const { type } = subtype;
        return {
            ...subtype,
            typeName: type.tbl_typeName
        }
    });


        // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(asetSubTypeRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data

    const columns = [
    
        { field: 'id', headerName: 'No', width: 100 },
        
       
        { field: 'typeName' , headerName: 'Asset Type Name', width: 280 },
       
        {
            field: 'tbl_subtypeName',
            headerName: 'Asset Sub Type Name',
            width: 480,
          },
                    
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showAssetSubType/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    <Link to={"/editAssetSubType/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" />
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];


    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
       
            {/* DISPLAYING ASSET SUB TYPE */}
            
          
            
            <div className="newUserTitle text-white bg-info">         
            <>         
                
                <label className="destroyedHeaderDisplay"> All Asset Sub-types</label>
                
                </>
            
            
             </div>


             {loading ? 
               <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
               :
               <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
             }

            {/*  Footer Call*/}
            <div className="footerHeight1">
               <hr />
                           
           </div>
           </div>
           </div>

           </div>
           </div>


    )
}
