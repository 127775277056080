import React from 'react'

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory , useParams} from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddCustodianAsset(props) {

    const { id } = useParams();

    const [custodasset, setCustodAsset] = useState(null);
    const [loading, setLoading] = useState(false);

    const [assetId, setAssetId] = useState('');
    const [staffId, setStaffId] = useState('');

    const [problem, setProblem] = useState('');
    let history = useHistory();

    const [assetDetailId, setAssetDetailId] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        } 

        const decoded = jwt_decode(localStorage.adminEmailToken); 

        //console.log(decoded);
    
        setStaffId(decoded.id);
        setAssetId(id);
  

        setLoading(true);
        const data = await getRoute('/custody-assets/view/'+id);
        setLoading(false);
        if (data) {
            setCustodAsset(data.custodasset);

            setAssetDetailId(data.custodasset.asset.id);
            //return false;
        }


        //if(localStorage.adminEmailToken){


        //}

        


    }, []);


    if(!loading && !custodasset){

        return <p>No Result</p>; 
    }





    //loading ? null : custodasset.asset.tbl_assetDesc
    
    const saveRequest = (event)  =>  {
        event.preventDefault();

        if(assetId.trim() == '' || assetId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(staffId.trim() == '' || staffId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(problem.trim() == '' || problem.trim() == null ){

            alert("Problem is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_custodassetID": assetId,
                "tbl_maintrequestDesc": problem,
                "tbl_maintrequestReportedBy": staffId,
                "tbl_maintrequestToken": randomString(5),
                "tbl_maintrequestStatus": true,

                "assetDetailId": assetDetailId,
            }

            setLoading(true);
            const data =  postRoute('/maintenance/',postData );
            
            if (data) {

                alert("Request Sent");
                setLoading(false);

                history.push("/custodianAssignedAssetReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
        <div className="addNewAsset">

    <h3 className="newUserTitle text-center bg-info text-white">
        Report asset: 
        <b className="text-warning"> - {custodasset.asset.tbl_assetDesc}</b>
    </h3>
                <form className="newUserForm" onSubmit={saveRequest}>





                    <div className="newUserItem">
                    <label>Problem</label>
                    <textarea onChange={(e) => setProblem(e.target.value)} rows="4" name="problem" id="problem" className="newUserSelect">
                
                    </textarea>
                    </div>
               
           
             
                   {/* <div className="buttons">                */}
                   <button className="newUserButton bg-info">
                       <Send className="text-warning"/> 
                          Send Request
                       </button>
                </form>
                
          
          </div> }
    </div>

    </div>
    </div>
    )
}
