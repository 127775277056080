import React from 'react'

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory , useParams} from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddCustodianAsset(props) {

    const { id } = useParams();

    const [supplier, setSupplier] = useState(null);
    const [types, setTypes] = useState([]);

    const [loading, setLoading] = useState(false);

    const [supplierId, setSupplierId] = useState('');

    const [typeId, setTypeId] = useState('');
    let history = useHistory();


    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        } 

        setSupplierId(id);
  
        setLoading(true);
        const data = await getRoute('/types');
        setLoading(false);
        if (data) {
            setTypes(data.types);
        }

        setLoading(true);
        const supplierData = await getRoute('/suppliers/view/'+id);
        setLoading(false);
        if (supplierData) {
            setSupplier(supplierData.supplier);
            //return false;
        }
    


    }, []);


    if(!loading && !supplier){

        return <p>No Result</p>; 
    }





    //loading ? null : custodasset.asset.tbl_assetDesc
    
    const saveSupplyAsset = (event)  =>  {
        event.preventDefault();

        if(supplierId.trim() == '' || supplierId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(typeId.trim() == '' || typeId.trim() == null ){

            alert("Type of asset is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_supplierID": supplierId,
                "tbl_typeID": typeId,
                "tbl_supplier_itemToken": randomString(5),
                "tbl_supplier_itemStatus": true,
            }

            setLoading(true);

            axios.post('http://localhost:8080/api/v1/supplier-types', postData)
        
            .then(res =>{
    
                if(res.data.user){

                    setLoading(false);

                    alert("Supplying asset has been registered");
                    history.push("/suppliersReport");

                }else if(res.data.error){

                    setLoading(false);

                    alert("Process Failed");
                    return false;
                    
                }else if(res.data.supplierType){

                    setLoading(false);

                    alert("It is already assigned to him");

                    return false;

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            });

        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
        <div className="addNewAsset">

    <h3 className="newUserTitle text-center bg-info text-white">
        Add supplying asset to: 
        <b className="text-warning"> - {supplier.tbl_supplierFullName}</b>
    </h3>
                <form className="newUserForm" onSubmit={saveSupplyAsset}>

                <div className="newUserItem">
                        <label>Asset Type</label>
                        <select onChange={(e) => setTypeId(e.target.value)}  name="assetType" id="assetType" className="newUserSelect">
                        <option value="">---Asset Type----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          types.map(type =>(
                            <option value={type.id} >
                             {type.tbl_typeName}
                            </option>
                        )) }

                        </select>
                        </div>     
             
                   {/* <div className="buttons">                */}
                   <button className="newUserButton bg-info">
                          Save
                       </button>
                </form>
                
          
          </div> }
    </div>

    </div>
    </div>
    )
}
