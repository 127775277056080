import React from 'react'
import { Link } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import {facilityRows} from "../../../allData";
// Icons
import {Close, DeleteOutline, Send} from "@material-ui/icons";

// Delete
import { useState, useEffect } from "react";
import {getRoute} from '../../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ViewFacility() {

    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');
  
    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/facility');
        setLoading(false);
        if (data) {
            setFacilities(data.facilities);
        }
    }, []);
    console.log(facilities)

            // Diplay data
    // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(facilityRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 150 },
          // Image call
          { field: 'tbl_facilityName', headerName: 'Facility Name', width: 200, renderCell:(params)=>{
            return (
                <div className="userListUser">
                    <img className="userListImg" src={"../dist/img/avatar.png"} alt="" />
                    {params.row.tbl_facilityName}
                </div>
            )
            //End  Image call
        } },
       
        
        { field: 'tbl_facilityDesc', headerName: 'Facility Description', width: 400 },
       
               
          
          {
            field: 'action',
            headerName: 'Action',
            width: 250,
            
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showAssetFacility/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" title="View" />
                        </button>
                    </Link>
                    <Link to={"/editFacility/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" title="Edit" />
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];
    // End Display Data
    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
              <div className="viewEmployee"> 
             {/* DISPLAYING ASSET TYPE */}
             
             <div className="newUserTitle text-white bg-info">         
          <>               
              
              <label className="destroyedHeaderDisplay">All Campus Facilities</label>
              
              </>
          
          
           </div>
             {loading ? <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> :<DataGrid className="dataGrid" rows={facilities} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>}


            {/* Footer Call */}
            <div className="footerHeight1">
               <hr />
                           
           </div>
            
        </div>
        </div>

        </div>
        </div>
    )
}
