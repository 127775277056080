
import React, { Component } from 'react'

import "./dashboard.css";

import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import jwt_decode from 'jwt-decode';
import {getRoute, postRoute } from '../../api/methods';

import Header from '../header/Header';
import Menu from '../menu/Menu';

//export default class Dashboard extends Component {
export default function Dashboard(props) {

  let history = useHistory();

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {

    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    }else{

      history.push("/");


    }
    
      setLoading(true);
      const data = await getRoute('/dashboard');
      setLoading(false);
      if (data) {
        setReport(data.report);
      }
  }, []);
  console.log(report)



  






  if(!loading && !report){

    return <p>No  Result found</p>; 
  }
  
  


        return (

          <div>
          <Header/> 
    
          <div class="wrapper">
            <Menu role={userRole} full={userFullName}  /> 

            <div>
            {loading ? 
              <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :<div className="content-wrapper bg-white">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">Dashboard</h1>
          </div>{/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
           
               <li className="breadcrumb-item ">
                <Link to="/Dashboard" className="link">Home</Link></li>
              <li className="breadcrumb-item active">My Dashboard</li>
            </ol>
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    {/* Main content */}
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">

        <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">

                <h4 className="text-center">Assets For Facilities</h4>

                { loading ? <option value="">Loading ...</option> : 

                      report.facilityValue.map( currentFacValue =>(

                        currentFacValue.assetValueForFac[0].tbl_assetAcquisValue ?
                          <h6>
                          <Link to={"/registeredAssetReport"} className={"link  text-white"}>
                            {currentFacValue.tbl_facilityName+" : " +currentFacValue.assetValueForFac[0].tbl_assetAcquisValue+" Rwfs ("+currentFacValue.assetNumberForFac+")"}   
                            
                          </Link>
                          </h6>
                        : null
                        
                      ))}
              
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* col1 */}

          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">

                <h4 className="text-center">Assets Categories</h4>

                { loading ? <option value="">Loading ...</option> : 

                      report.categoryValue.map( currentAssetSubValue =>(

                        <h6>
                          <Link to={"/registeredAssetReport"} className={"link  text-white"}>
                            {currentAssetSubValue.tbl_subtypeName+" : " +currentAssetSubValue.assetValueForCateg[0].tbl_assetAcquisValue+" Rwfs ("+currentAssetSubValue.assetNumberForCateg+")"}   
                            
                          </Link>
                        </h6>
                         

                      ))}
              
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* col2 */}



        <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Assets ({report.assetNumber})</h4>
                <Link to="/registeredAssetReport" className="link  text-white">
                  Value: 
                  <span className="float-right text-dark">
                    ({" "+report.assetValue[0].tbl_assetAcquisValue+ " Rwfs"})
                  </span> 
                </Link> <br/>
                
                <Link to="/maintainceAssetReport" className="link text-white">
                  Maintainance Cost 
                  <span className="float-right text-dark">
                    ({" "+report.repairedCost[0].tbl_maintainanceCost+ " Rwfs"})
                  </span>
                </Link>
              
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* col3 */}



        <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Assets ({report.assetNumber})</h4>
                <Link to="/registeredAssetReport" className="link  text-white">
                  Working 
                  <span className="float-right text-dark">({report.assetNumber})</span> 
                </Link> <br/>
                
                <Link to="/maintainceRequestReport" className="link text-white">
                  Destroyed 
                  <span className="float-right text-dark">
                    ({report.reportedNumber})
                  </span>
                </Link>
                <br/>
                <Link to="/maintainceAssetReport" className="link text-white">
                  Maintained 
                  <span className="float-right text-dark">
                    ({report.repairedNumber})
                  </span>
                </Link>
              
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Assets ({report.assetNumber})</h4>
                <Link to="/assetRenting" className="link  text-white">
                  Rent 
                  <span className="float-right text-dark">
                    ({report.rentNumber})
                  </span> 
                </Link> <br/>
                <Link to="/allReturnedAssets" className="link text-white">
                  Returned  
                  <span className="float-right text-dark">
                    ({report.returnedNumber})
                  </span>
                </Link>
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Assets ({report.assetNumber})</h4>
                <Link to="/custodianAssignedAssetReport" className="link  text-white">
                  Assigned to Custodian 
                  <span className="float-right text-dark">({report.assignedNumber})</span> 
                </Link> <br/>

                <Link to="/custodianAssignedAssetReport" className="link text-white">
                  Not Assignemd 
                  <span className="float-right text-dark">
                    ({report.assignedNumber - report.assignedNumber})
                  </span>
                </Link>
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
         
          {/* ./col */}
          {/*
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Assets ({report.assetNumber})</h4>
                <Link to="/movementReport" className="link  text-white">Movement <span className="float-right text-dark">(80)</span> </Link> <br/>
                <Link to="/movementReport" className="link text-white">Not Moved <span className="float-right text-dark">(70)</span></Link>
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>

          */}
         
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h4>Total Suppliers (10)</h4>
                <Link to="/suppliersReport" className="link  text-white">
                  Working 
                  <span className="float-right text-dark">
                    ({report.supplierNumber})
                  </span>
                </Link> <br/>

                <Link to="/suppliersReport" className="link text-white">
                  Not Working 
                  <span className="float-right text-dark">
                    ({report.inactiveSupplierNumber})
                  </span>
                </Link>
              </div>
             
              <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
        </div>
        {/* /.row */}
        {/* Main row */}
        <div className="row">
          {/* Left col */}
{/* Left CARD */}


{/* PIE CHART */}
          {/* right col */}
        </div>
        {/* /.row (main row) */}
      </div>{/* /.container-fluid */}
    </section>
    {/* /.content */}
  </div>}
  
</div>

</div>
</div>
        )
    
}
