import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowAssetFacility() {

    const [facility, setFacility] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/facility/fac/'+id);
        setLoading(false);
        if (data) {
            setFacility(data.facility);
            //return false;
        }

    }, []);


    if(!loading && !facility){

        return <p>No Facility</p>; 
    }

    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
            {loading ? 
                <center><img  src={"../../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="facility">

  {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">
     
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">

    <div className="userShowTop">
    <img src="https://www.zdnet.com/a/hub/i/r/2020/03/19/4d0e4e03-c980-43db-aa0f-2572aefcf89f/thumbnail/770x433/36d847f1d279f3a0cbaf29e1e9fc576f/surface-laptop-3-13-5-header.jpg" 
    alt="No Imange" className="userShowImg" />
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
        {facility.tbl_facilityName}
    </span>
    {/* <span className="userShowUserTitle">Muhabura</span> */}
    </div>
    </div>
   
    <div className="userShowBottom">
    <span className="userShowTitle text-dark font-weight-bold ">Facility Details</span>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
        {facility.tbl_facilityName}
    </span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">
      {facility.tbl_facilityDesc}
    </span>
    </div>
   <Link to="/registeredFacilitiesReport">
   <button className="btn btn-info">Back</button>
   </Link>
    </div>
    </div>
    
    </div>   
    </div>}
            
        </div>

        </div>
        </div>
    )
}
