import React from 'react'
import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowAssetRent() {

    const { id } = useParams();

    const [rent, setRent] = useState(null);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/rent/view/'+id);
        setLoading(false);
        if (data) {
            setRent(data.rentasset);
        }

    }, []);

    if(!loading && !rent){

        return <p>No result found</p>; 
    }

    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">

           {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
            :
        <div className="editAssetType">

<div className="userContainer">
<div className="userShow">

<div className="userShowBottom">
<span className="userShowTitle font-weight-bold text-dark">Asset Rent Details</span>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle ">
    Asset Name:
    <b class="text-primary">
        {" "+rent.assetTobeRent.tbl_assetDesc}
    </b> 
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
Asset Code:
    <b class="text-primary">
      {" "+rent.assetTobeRent.tbl_assetCode}
    </b>
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Rent On:

    <b class="text-primary">
      {" "+rent.createdAt}
    </b>

</span>
</div>



</div>
</div>
<div className="userUpdate">
  
<form className="userUpdateForm">

<div className="userUpdateLeft">
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
    Expected return date: 
   <b class="text-success">
      {" "+rent.tbl_rent_assetExpReturn}
    </b>
</span>
</div>

<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
   Borrower: 
   <b class="text-primary">
      {" "+rent.tbl_rent_assetBorrowBy}
    </b>
</span>
</div>
<div className="userShowInfo">
{/* Icon */}

<CreditCard className="userShowIcon text-dark"/>
<span className="userShowInfoTitle">
   Reason: 
   <b class="text-primary">
      {" "+rent.tbl_rent_assetReason}
    </b>
</span>
</div>

<Link to="/assetRenting">
 <button className="btn btn-info">Back</button>
 </Link>

 <Link to={"../returnedAsset/"+id} > 
          <button className="btn btn-info">
            <i className="fa fa-plus"></i>
            Add To Returned
          </button>
        </Link>
</div>

</form>
</div>
</div>   



        {/* Footer */}
        <div className="footerHeight1">
           <hr />
                       
       </div>
        </div> }
    </div>

    </div>
    </div>
    )
}
