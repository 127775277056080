import React from 'react'

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../../api/methods';

import { useHistory, useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';

import jwt_decode from 'jwt-decode';

import Header from '../../header/Header';
import Menu from '../../menu/Menu';

export default function ShowNewAsset() {

    const [asset, setAsset] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();
    const [custodLoading, setCustodLoading] = useState(false);

    const [custodAssets, setCustodyAssets] = useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 
  
        setUserEmail(decoded.tbl_staffEmail);
        setUserRole(decoded.staffFoundRole.name);
  
        setUserFullName(decoded.tbl_staffFullName);
        setUserId(decoded.id);
  
      }
        setLoading(true);
        const data = await getRoute('/assets/asset/'+id);
        setLoading(false);
        if (data) {
            setAsset(data.asset);
            //return false;
        }

        //custody assets
        setCustodLoading(true);
        const custoodyData = await getRoute('/custody-assets/related/'+id);
        setCustodLoading(false);
        if (custoodyData) {
          setCustodyAssets(custoodyData.custodAssets);
        }


    }, []);

    //console.log(asset);

    // custody assets
    const tableData = custodAssets.map((custodAsset, index) => {
      const { asset, staff, user, workingLocation } = custodAsset;
      
      return {
          ...custodAsset,
          assetName: asset.tbl_assetDesc + (custodAsset.tbl_custodassetStatus== true ? " (Still there)": "(Moved)"),

          assetCode: asset.tbl_assetCode,
          staffName: staff.tbl_staffFullName,

          staffNid: staff.tbl_staffNID,
          userFullName: user.tbl_staffFullName,

          workInfo : workingLocation.tbl_facilityName,
          workCode : workingLocation.tbl_facilityCode,

      }
    });


    //columns

    const columns = [
      { field: 'id', headerName: 'No', width: 20 },
     
      {
          field: 'staffName',
          headerName: 'Employee Name',
          width: 180,
        },
        {
          field: 'workInfo',
          headerName: 'Facility',
          width: 180,
        },
        {
          field: 'workCode',
          headerName: 'Facility Code',
          width: 180,
        },

        {
          field: 'tbl_custodassetFrom',
          headerName: 'From',
          width: 180,
        },

        {
          field: 'tbl_custodassetTo',
          headerName: 'To',
          width: 180,
        },

    
    ];


    if(!loading && !asset){

      return <p>No asset</p>; 
    }

    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="editNewAsset">

        <div className="userTitleContainer">
 
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
    <img  src={"../dist/img/"+asset.tbl_assetPhoto} alt="No Imange" className="userShowImg" />
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
       {asset.tbl_assetDesc}
    </span>
    <span className="userShowUserTitle">
        {asset.facility.tbl_facilityName}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Asset Details</span>
    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Asset Code: {asset.tbl_assetCode}
    </span>
    </div>
    

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
      Brand: {asset.tbl_assetBrand}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       Model: {asset.tbl_assetModel}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
       SN: {asset.tbl_assetSN}
    </span>
    </div>

    <div className="userShowInfo">
    
    <CreditCard className="userShowIcon text-dark" />
    <h5 className="userShowInfoTitle">
       Owner: Campus's Asset
    </h5>
    </div>


    </div>
    </div>

    </div>

    <div className="footerHeight1">
               <hr />
                           
           </div>
    </div> }
            
        </div>

        </div>
        </div>
    )
}
