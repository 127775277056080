import React from 'react'

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams, Link } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ShowEmployee() {

    const [staff, setStaff] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');


    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/staffs/view/'+id);
        setLoading(false);
        if (data) {
            setStaff(data.staff);
            //return false;
        }

    }, []);

    if(!loading && !staff){

        return <p>No Result yet </p>; 
    }



    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 
        <div className="content-wrapper bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
                :
            <div className="editEmployee">
             {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">

        
        
        </div>
        
        <div className="userContainer">
        <div className="userShow">
        <div className="userShowTop">
        <img src={"../dist/img/avatar.png"} 
        alt="No Imange" className="userShowImg" />
        <div className="userShoeTopTitle">
        <span className="userShowUsername">
            {staff.tbl_staffFullName}
        </span>
        <span className="userShowUserTitle">
            {staff.tbl_staffJobTitle}
        </span>
        </div>
        </div>
        <div className="userShowBottom">
        <span className="userShowTitle text-dark font-weight-bold">Account Details</span>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Department: {staff.department.tbl_departmentName}
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Field: {staff.field.tbl_fieldName}
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
          Level: {staff.level.tbl_levelQualification}
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            National ID: {staff.tbl_staffNID} 
        </span>
        </div>
     
      
        </div>
        </div>
        {/* RIGHT SIDE */}
        <div className="userUpdate">        
        <form className="userUpdateForm">
        <div className="userUpdateLeft">
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Marital Status: {staff.tbl_staffMuritalStatus} 
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark" />
        <span className="userShowInfoTitle">
            Gender: {staff.tbl_staffGender} 
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Nationality: {staff.tbl_staffNationality}  
        </span>
        </div>


        {/* <span className="userShowTitle">Contact Details</span> */}
       
        <div className="userShowInfo">
        {/* Icon */}
        
        <MailOutline className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            E-Mail: {staff.tbl_staffEmail} 
        </span>
        </div>
        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Username: {staff.tbl_staffUserName} 
        </span>
        </div>

        <div className="userShowInfo">
        
        {/* Icon */}
        
        <PhoneAndroid className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
          Phone Number: {staff.tbl_staffPhone}
        </span>
        </div>

        <div className="userShowInfo">
        {/* Icon */}
        
        <CreditCard className="userShowIcon text-dark"/>
        <span className="userShowInfoTitle">
            Date Of Birth: {staff.tbl_staffDOB}
        </span>
        </div>
        <Link to="/employeesReport">
        <button className="btn btn-info">Back</button>
        </Link>
        </div>
        </form>
        </div>
        </div>


        {/* Footer Call */}
        <div className="footerHeight1">
               <hr />
                           
           </div>
        </div> }
        </div>

        </div>
        </div>
        )
}
