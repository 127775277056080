import React from 'react'

import { Send, Close } from "@material-ui/icons";
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';


export default function AddAssetMovement(props) {

    const [loading, setLoading] = useState(false);
    const [staff, setStaff] = useState([]);

    const [asset, setAsset] = useState(null);
    let history = useHistory();

    const { id } = useParams();
    const [assetId, setAssetId] = useState('');

    const [reason, setReason] = useState('');
    const [borrower, setBorrower] = useState('');

    const [returnDate, setReturn] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
    
            const decoded = jwt_decode(localStorage.adminEmailToken); 
    
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
    
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
    
        }

        setLoading(true);
        const data = await getRoute('/assets/asset/'+id);
        setLoading(false);
        if (data) {
            setAsset(data.asset);
            //return false;
        }

        setAssetId(id);

        const decoded = jwt_decode(localStorage.adminEmailToken); 
        setStaff(decoded.id);


    }, []);


    const saveRent = (event)  =>  {
        event.preventDefault();

        alert(staff);

        if(staff.trim() == '' || staff.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(assetId.trim() == '' || assetId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(reason.trim() == '' || reason.trim() == null ){

            alert("Reason is required");
            return false;

        }else if(borrower.trim() == '' || borrower.trim() == null ){

            alert("Borrower is required");
            return false;

        }else if(returnDate.trim() == '' || returnDate.trim() == null ){

            alert("Expected return date is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_assetID": assetId,
                "tbl_rent_assetRecordedBy": staff,
                "tbl_rent_assetBorrowBy": borrower,
                "tbl_rent_assetFee": "1500",
                "tbl_rent_assetToken": randomString(5),
                "tbl_rent_assetStatus": true,

                "tbl_rent_assetExpReturn": returnDate,
                "tbl_rent_assetReason": reason ,
            }

            setLoading(true);
            const data =  postRoute('/rent/',postData );
            
            if (data) {

                alert("Asset has been rent");
                setLoading(false);

                history.push("/assetRenting");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }


    if(!loading && !asset){

        return <p>No Result</p>; 
    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">

           {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
            : 
        <div className="addNewAsset">
 
    <h3 className="newUserTitle text-center bg-info text-white">
        Rent Asset:   
        <b className="text-warning">
            {asset.tbl_assetDesc} -  {asset.tbl_assetCode}
        </b>
    </h3>
                <form className="newUserForm" onSubmit={saveRent}>
               
                    <div className="newUserItem">
                        <label>Reason</label>
                        <textarea onChange={(e) => setReason(e.target.value)} name="movementReason" id="movementReason" cols="30" rows="10" className="newUserSelect"></textarea>
                    </div> 

                    <div className="newUserItem">
                        <label>Borrowed By</label>
                        <input onChange={(e) => setBorrower(e.target.value)} type="receivedBy" placeholder="received By" name="receivedBy" id="receivedBy" />
                    </div>  

                    <div className="newUserItem">
                        <label>Expected Return</label>
                        <input onChange={(e) => setReturn(e.target.value)} type="date" placeholder="" name="return" id="return" />
                    </div>            
             
                   {/* <div className="buttons">                */}
                   <button className="newUserButton bg-info">
                       <Send className="text-warning"/> Add
                    </button>
                </form>
                
         
          </div>}
    </div>

    </div>
    </div>

    )
}
