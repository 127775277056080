import React from 'react'

import { useState, useEffect } from "react";
import {employeeRows} from "../../allData";
import {DeleteOutline} from "@material-ui/icons";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

import {getRoute} from '../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewEmployee() {
    const [staffs, setStaffs] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/staffs');
        setLoading(false);
        if (data) {
            setStaffs(data.staffs);
        }
    }, []);
    console.log(staffs)
// Display Employee
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(employeeRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
         // Image call
        { field: 'tbl_staffFullName', headerName: 'Full Name', width: 250, renderCell:(params)=>{
            return (
                <div className="userListUser">
                    <img className="userListImg" src={"dist/img/etienno.jpg"} alt="" />
                     {params.row.tbl_staffFullName}
                </div>
            )
            //  //End  Image call
        } 

        },
        { field: 'tbl_staffUserName', headerName: 'Username', width: 230 },
      
        
       
        { field: 'tbl_staffNID', headerName: 'NID', width: 230 },
        
       
           {
             field: 'action',
             headerName: 'Action',
             width: 150,
             renderCell: (params)=>{
                 return(
                     <>
                     <Link to={"/showEmployee/"+params.row.id}>
                    <button className="userListEdit bg-info" title="View">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    
                     <Link to={"/editEmployee/"+params.row.id}>
                     <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" />
                         </button>
                     </Link>
                     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                    </>
                 )
             }
           },
      
      ];
      

// End DISPLAY EMPLOYEE

    return ( 

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
        <div className=" viewEmployee ">
            
            <div className="newUserTitle text-white bg-info">         
            <>               
                
                <label className="destroyedHeaderDisplay"> All Registered Employees</label>
                
               
                </>
            
            
             </div>
           
           {loading ? <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> : <DataGrid className="dataGrid" rows={staffs} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>}
          
         {/* Footer Call */}
       <div className="footerHeight1">
          <hr />
                      
      </div>
        </div>
        </div>

        </div>
        </div>
       
    )
}
