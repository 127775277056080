import React from 'react'
import { Link } from 'react-router-dom';
import {Close, DeleteOutline, Send} from "@material-ui/icons";

import { DataGrid } from '@mui/x-data-grid';
import {allLevelRows} from "../../allData";

// Delete
import { useState, useEffect } from "react";
import {getRoute} from '../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewLevel() {

    const [levels, setLevels] = useState([]);
    const [loading, setLoading] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/levels');
        setLoading(false);
        if (data) {
            setLevels(data.levels);
        }
    }, []);
    console.log(levels)

        // Diplay data
    // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allLevelRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 200 },
        // Image call
       
        { field: 'tbl_levelQualification', headerName: 'Level (Qualification) Name', width: 660 },
       
               
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showLevel/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    <Link to={"/editLevel/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" />
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                   
                    </>
                )
            }
          },
      
      ];
    // End Display Data
    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
        <div className="addAssetType">


                         {/* DISPLAYING ASSET TYPE */}
                       
             
             <div className="newUserTitle text-white bg-info">         
             <>         
                 
                 <label className="destroyedHeaderDisplay"> All Level (Qualification)</label>
                 
                                
                     
                 
                 </>
             
             
              </div>
 
 
              {loading ? <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> :<DataGrid className="dataGrid" rows={levels} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>}
 
 
             {/* Footer Call */}
             <div className="footerHeight1">
                <hr />
                            
            </div>
            
        </div>
        </div>

        </div>
        </div>
    )
}
