import React, { Component } from 'react';
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, Link } from "react-router-dom";

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default class Forgot extends Component {
    render() {
        const paperStyle={padding :20,height:'50vh',width:440,  margin:"20px auto"}
        const avatarStyle={backgroundColor:'#201bbd'}
        const btnstyle={margin:'8px 0'}
        return (

            <div>
            <Header/> 
      
            <div class="wrapper">
              <Menu role="login" full="Forgot Password"  /> 

            <div class="col-md-12 mt-5"><br /><br />
                  <Grid class="col-md-12">
            <Paper elevation={10} style={paperStyle} >
                <Grid align='center'>
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <h2 class="text-primary">OIMS Reset Password</h2>
                </Grid>
                <hr class="bg-light"/>
                <form method="post">                
                <input type="email" className="form-control mt-3" fullWidth required placeholder='Enter Email'/>              
               
                <Button type='submit' color='primary'className="mt-3" variant="contained" style={btnstyle} fullWidth>Reset</Button>
                </form>
                <hr class="bg-light"/>
                <Typography >
                     <Link to="/" class="text-primary" >
                        Login
                </Link>
                </Typography>
                
              
            </Paper>
        </Grid>

        <footer class="footer fixed-bottom text-center text-white">Alright Reserver By <span class="text-white">University of Rwanda </span></footer>
                
            </div>

            </div>
            </div>
        )
    }
}
