import React from 'react'

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import { Send } from '@material-ui/icons';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddAssetSubType() {  

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [typeId, setTypeId] = useState('');
    const [subTypeName, setSubTypeName] = useState('');

    let history = useHistory();


    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){

            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
            setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/types');
        setLoading(false);
        if (data) {
            setTypes(data.types);
        }
    }, []);
    //console.log(types);


    const saveSubType = (event)  =>  {
        event.preventDefault();

        if(typeId.trim() == '' || typeId.trim() == null ){

            alert("Type of asset is required");
            return false;

        }else if(subTypeName.trim() == '' || subTypeName.trim() == null ){

            alert("Name is required");
            return false;

        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_typeID": typeId,
                "tbl_subtypeName": subTypeName,
                "tbl_subtypeToken": randomString(5),
                "tbl_subtypeStatus": true,
            }

            setLoading(true);
            const data =  postRoute('/subtypes',postData );
            
            if (data) {

                alert(subTypeName+" has been added");
                setLoading(false);

                history.push("/registeredAssetSubTypeReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }

    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper  bg-white">
            <div className="assetSubType">
              <h3 className="newUserTitle text-center text-white bg-info">Adding  Asset Type </h3>
            <form className="newUserForm" onSubmit={saveSubType}>
            
                <div className="newUserItem">
                <label>Asset Type</label>
                <select onChange={(e) => setTypeId(e.target.value)} name="assetType" id="assetSubType" className="newUserSelect">
                    <option value="">---Asset Type----</option>

                    { loading ? <option value="">Loading ...</option> : 
                      types.map(type =>(
                       <option value={type.id} >
                           {type.tbl_typeName}
                       </option>
                    )) }
                </select>
                </div>
                <div className="newUserItem">
                    <label>Asset Sub Type Name</label>
                    <input onChange={(e) => setSubTypeName(e.target.value)} type="text" placeholder="Laptop" name="assetType" id="assetType" required />
                </div>
                
                
               
                
               {/* <div className="buttons">                */}
               <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button>
                </div> */}
            </form>

            </div>
        </div>

        </div>
        </div>
    )
}
