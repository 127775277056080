import React from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allcustodianRows} from "../../allData";
// Delete
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewCustodianAsset() {

  const [custodAssets, setCustodyAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [departs, setDeparts] = useState([]);
  const [departLoading, setDepartLoading] = useState(false);

  const [logRole, setLogRole] = useState(false);
  const [initialCustodAssets, setInitialCustodyAssets] = useState([]);

  const [currentDepart, setCurrentDepart] = useState([]);
  const [loadCurrDepart, setLoadCurrDepart] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const onChangeDepartment = ({ target }) => {
    const departId = target.value;

    if(departId == null){

      setCustodyAssets(initialCustodAssets);
      setLoadCurrDepart(" All Assets used in all departments ");
      
    }else{

      const newDeparts = departs.filter(newDepart => 
        newDepart.id === departId);
        setCurrentDepart(newDeparts);

        setLoadCurrDepart(" All Assets used in this department ");

      const newCustodAssets = initialCustodAssets.filter(newCustodAsset => 
        newCustodAsset.staff.tbl_departmentID === departId);
        setCustodyAssets(newCustodAssets);

    }

    
  }

  useEffect(async () => {

    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    } 

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 

        if(decoded.staffFoundRole.name == "custodian" 
          || decoded.staffFoundRole.name == "IT" || 
          decoded.staffFoundRole.name == "HR" || 
          decoded.staffFoundRole.name == "lab-manager"  
        ){

          setLoading(true);
          const data = await getRoute('/custody-assets/emp/'+decoded.id);
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);
          }
        
        }else if(decoded.staffFoundRole.name == "asset-manager"){

          setLogRole(decoded.staffFoundRole.name);
          setLoading(true);
          
          const data = await getRoute('/custody-assets');
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);

            setInitialCustodyAssets(data.custodAssets);
          }

          setDepartLoading(true);
          const departData = await getRoute('/departs');
          setDepartLoading(false);
          if (departData) {
              setDeparts(departData.departs);
          }



        }

        setLoadCurrDepart(" All Assets used in all departments  ");



      }


  }, []);
  //console.log(custodAssets)

        // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allcustodianRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data

    if(!custodAssets){

      return <p>Loading .....</p>
    }

    let totalDepr;
    let calcPrior;

    const tableData = custodAssets.map((custodAsset, index) => {
      const { asset, staff, user } = custodAsset;
      
      if(new Date().getFullYear()  === new Date(asset.createdAt).getFullYear()){

        calcPrior = 0;

      }else{

        calcPrior = (new Date().getFullYear()  - new Date(asset.createdAt).getFullYear() -1) * 
        (asset.tbl_assetAcquisValue / custodAsset.assetTypeInfo.tbl_typeLifeSpan);
      }

      totalDepr = ((new Date().getFullYear()  - new Date(asset.createdAt).getFullYear()) * 
      (asset.tbl_assetAcquisValue / custodAsset.assetTypeInfo.tbl_typeLifeSpan)) + 
      
      (calcPrior);

      let accumulativeDepr = totalDepr + calcPrior;

      let netBook = asset.tbl_assetAcquisValue - accumulativeDepr ;
      
      return {
          ...custodAsset,
          assetName: asset.tbl_assetDesc,

          assetCode: asset.tbl_assetCode,
          staffName: staff.tbl_staffFullName,

          staffNid: staff.tbl_staffNID,
          userFullName: user.tbl_staffFullName,

          assetAcq: new Intl.NumberFormat('en-US', 
          {style: 'currency',currency: 'RWF',})
          .format(asset.tbl_assetAcquisValue),

          assetYear: new Date(asset.createdAt).getFullYear(),

          assetDepr: new Intl.NumberFormat('en-US', 
          {style: 'currency',currency: 'RWF',})
          .format(
          (new Date().getFullYear()  - new Date(asset.createdAt).getFullYear()) * 
          (asset.tbl_assetAcquisValue / custodAsset.assetTypeInfo.tbl_typeLifeSpan)),

          prior:  
          new Intl.NumberFormat('en-US', 
          {style: 'currency',currency: 'RWF',})
          .format(calcPrior),

          accDepr : new Intl.NumberFormat('en-US', 
          {style: 'currency',currency: 'RWF',})
          .format(accumulativeDepr),

          netBookValue : new Intl.NumberFormat('en-US', 
          {style: 'currency',currency: 'RWF',})
          .format(netBook),

          

           
          

      }
  });

    const columns = [
        { field: 'id', headerName: 'No', width: 20 },
        {
            field: 'assetName',
            headerName: 'Description',
            width: 150,
          },

          {
            field: 'assetCode',
            headerName: 'Asset Code',
            width: 180,
          },


          { field: 'assetAcq', headerName: 'Acqu Value', width: 150 },
          { field: 'assetYear', headerName: 'Acqu Year', width: 150 },

          {
            field: 'assetDepr',
            headerName: 'Depr ',
            width: 180,
          },

          {
            field: 'prior',
            headerName: 'Prior Depr ',
            width: 180,
          },

          {
              field: 'accDepr',
              headerName: 'Acc Depr',
              width: 180,
            }, 

            {
                field: 'netBookValue',
                headerName: 'Net Value',
                width: 180,
              },     
      
      ];



// End Display Data
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
             {/* DISPLAYING ASSET */}

        <div className="newUserTitle text-white bg-info">         
        <>               
            
            <label className="destroyedHeaderDisplay">  
                <b>{loadCurrDepart}</b>
            </label>
            
            {logRole == "asset-manager" ? 
              <select onChange={onChangeDepartment} name="assetType" id="assetSubType" className="newUserSelectDisplay">
                <option value="">---Department----</option>
                {departLoading ? <option value="">---Loading----</option> :
                    departs.map(depart =>(
                      <option value={depart.id} >
                        {depart.tbl_departmentName}
                      </option>
                    )) 
                }
              </select>
            : null }
            </>
        
        
         </div>
         {loading ? 
            <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
            <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
            }

              {/* Footer Call */}
              <div className="footerHeight1">
           <hr />
                       
       </div>

            
        </div>
        </div>

        </div>
        </div>
    )
}
