import React from 'react'

import { Send, Close } from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import axios from 'axios';

export default function AddEmployee() {

    let history = useHistory();

    const [departs, setDeparts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [departId, setDepartId] = useState('');
    const [fieldId, setFieldId] = useState('');

    const [fields, setFields] = useState([]);
    const [levels, setLevels] = useState([]);

    const [levelId, setLevelId] = useState('');
    const [fullName, setFullName] = useState('');

    const [nid, setNid] = useState('');
    const [status, setStatus] = useState('');

    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [dob, setDob] = useState('');
    const [username, setUserName] = useState('');

    const [title, setTitle] = useState('');
    const [profile, setProfile] = useState('');

    const [roleId, setRoleId] = useState('');
    const [roles, setRoles] = useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        }

        setLoading(true);
        const data = await getRoute('/departs');
        setLoading(false);
        if (data) {
            setDeparts(data.departs);
        }

        const fieldData = await getRoute('/fields');
        if (fieldData) {
            setFields(fieldData.fields);
        }

        const levelData = await getRoute('/levels');
        if (levelData) {
            setLevels(levelData.levels);
        }

        const roleData = await getRoute('/all-roles');
        if (roleData) {
            setRoles(roleData.roles);
        }

    }, []);
 

    const saveEmployee = (event)  =>  {
        event.preventDefault();

        if(departId.trim() == '' || departId.trim() == null ){

            alert("Department is required");
            return false;

        }else if(fieldId.trim() == '' || fieldId.trim() == null ){

            alert("Field is required");
            return false;

        }else if(levelId.trim() == '' || levelId.trim() == null ){

            alert("Level is required");
            return false;

        }else if(fullName.trim() == '' || fullName.trim() == null ){

            alert("Name is required");
            return false;

        }else if(nid.trim() == '' || nid.trim() == null ){

            alert("National ID is required");
            return false;

        }else if(status.trim() == '' || status.trim() == null ){

            alert("Status is required");
            return false;

        }else if(gender.trim() == '' || gender.trim() == null ){

            alert("Gender is required");
            return false;

        }else if(nationality.trim() == '' || nationality.trim() == null ){

            alert("Nationality is required");
            return false;

        }else if(email.trim() == '' || email.trim() == null ){

            alert("E-Mail is required");
            return false;

        }else if(phone.trim() == '' || phone.trim() == null ){

            alert("Phone is required");
            return false;

        }else if(dob.trim() == '' || dob.trim() == null ){

            alert("Date of birth is required");
            return false;

        }else if(username.trim() == '' || username.trim() == null ){

            alert("Username is required");
            return false;

        }else if(roleId.trim() == '' || roleId.trim() == null ){

            alert("Role is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                tbl_staffFullName: fullName,
                tbl_staffPhone: phone,
      
                tbl_staffGender: gender,
                tbl_staffDOB: dob,
      
                tbl_staffNID: nid,
                tbl_staffMuritalStatus: status,
      
                tbl_staffNationality: nationality,
                tbl_staffPhoto: profile,
      
                tbl_staffUserName: username,
                tbl_staffPassword: username,
      
                tbl_staffJobTitle: title,
                tbl_departmentID: departId,
      
                tbl_levelID: levelId,
                tbl_fieldID: fieldId,
      
                role_id: roleId,
                tbl_staffToken: randomString(5),
                tbl_staffStatus: true,
      
                tbl_staffEmail: email,  

            }

            setLoading(true);

            axios.post('http://localhost:8080/api/v1/staffs', postData)
        
            .then(res =>{


                console.log(res.data);
    
                if(res.data.user){

                    setLoading(false);

                    alert(fullName+" has been registered");
                    history.push("/employeesReport");


                }else if(res.data.error){

                    setLoading(false);
                    alert("Process Failed");
                    history.push("/employeesReport");
                    
                }else if(res.data.checkUsername){

                    setLoading(false);

                    alert("Username is already taken");
                    return false;

                }else if(res.data.checkNid){

                    setLoading(false);

                    alert("National ID is already exist");
                    return false;

                }
    
            }).catch (error =>{

                setLoading(false);
    
                alert('Something went wrong');
    
            });   


        }
        

    }

    return ( 

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
            <div className=" addEmployee">
           
           <h3 className="newUserTitle text-center text-white bg-info">Adding Employee </h3>
           <form className="newUserForm" onSubmit={saveEmployee}  >
           <div className="newUserItem">
               <label>Department Name</label>
               <select onChange={(e) => setDepartId(e.target.value)} name="userCategory" id="userCategory" className="newUserSelect">
                    <option value="">----Select----</option>
                    { loading ? <option value="">Loading ...</option> : 
                        departs.map(depart =>(
                            <option value={depart.id} >
                             {depart.tbl_departmentName}
                            </option>
                        )) 
                    }
               </select>
               </div>
               <div className="newUserItem">
               <label>Field</label>
               <select onChange={(e) => setFieldId(e.target.value)} name="userCategory" id="userCategory" className="newUserSelect">
                   <option value="">----Select----</option>
                   { loading ? <option value="">Loading ...</option> : 
                        fields.map(field =>(
                            <option value={field.id} >
                             {field.tbl_fieldName}
                            </option>
                        )) 
                    }
               </select>
               </div>

               <div className="newUserItem">
               <label>Level</label>
               <select onChange={(e) => setLevelId(e.target.value)} name="userCategory" id="userCategory" className="newUserSelect">
                   <option value="">----Select----</option>
                   { loading ? <option value="">Loading ...</option> : 
                        levels.map(level =>(
                            <option value={level.id} >
                             {level.tbl_levelQualification}
                            </option>
                        )) 
                    }
               </select>
               </div>

               <div className="newUserItem">
               <label>Type of user</label>
               <select onChange={(e) => setRoleId(e.target.value)} name="userCategory" id="userCategory" className="newUserSelect">
                   <option value="">----Select----</option>
                   { loading ? <option value="">Loading ...</option> : 
                        roles.map(role =>(
                            <option value={role.id} >
                             {role.name}
                            </option>
                        )) 
                    }
               </select>
               </div>

              
               <div className="newUserItem">
                   <label>Full Name</label>
                   <input onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Etienne Ntambara" name="fullname" id="fullname" />
               </div>
               <div className="newUserItem">
                   <label>NID (Identification)</label>
             <input onChange={(e) => setNid(e.target.value)}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}maxlength="16" 
             pattern=".{16}" title="16 numbers" placeholder="1199......"  name="nid" id="nid" /> 

               </div>
               <div className="newUserItem">
                   <label>Status</label>
                   <select onChange={(e) => setStatus(e.target.value)} name="gender" id="gender" className="newUserSelect">
                   <option value="">--Martual Status--</option>
                   <option value="Single">Single</option>
                   <option value="Married">Married</option>
                   <option value="Divorce">Divorced</option>
               </select>
               </div>

               <div className="newUserItem">
                   <label>Gender</label>
                   <select onChange={(e) => setGender(e.target.value)} name="gender" id="gender" className="newUserSelect">
                   <option value="">--Gender--</option>
                   <option value="Male">Male</option>
                   <option value="Female">Female</option>
               </select>
               </div>
               <div className="newUserItem">
                   <label>Nationality</label>
                   <select onChange={(e) => setNationality(e.target.value)} name="gender" id="gender" className="newUserSelect">
                   
                   <option value="">----Select ----</option>
                   <option value="nationality">Rwandan</option>
                   <option value="other">Other</option>
               </select>
               </div>
               <div className="newUserItem">
                   <label>Email</label>
                   <input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="email@gmail.com" name="email" id="email" />
               </div>
               <div className="newUserItem">
                   <label>Phone</label>
               <input onChange={(e) => setPhone(e.target.value)} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}maxlength="10" 
             pattern=".{10}" title="10 numbers" placeholder="078 37 16 761"  name="phone" /> 
               </div>
               <div className="newUserItem">
                   <label>Date of birth</label>
                   <input onChange={(e) => setDob(e.target.value)} type="date" placeholder="2021-09-16" name="dob" id="dob" />
               </div>
               <div className="newUserItem">
                   <label>username</label>
                   <input onChange={(e) => setUserName(e.target.value)} type="text" placeholder="etienne" name="username" id="username" minLength="6" />
               </div>
               <div className="newUserItem">
                   <label>Job Title</label>
                   <input onChange={(e) => setTitle(e.target.value)} type="text" placeholder="IT" name="jobTitle" id="jobTitle" />
               </div>
               <div className="newUserItem">
                            <label>Profile </label>
                            <input onChange={(e) => setProfile(e.target.value)} type="file" placeholder="Etienne Ntambara" />
                        </div>

                 
          {/* <div className="buttons">    */}
                     
          <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
           {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button> */}
           {/* </div> */}

           
              
           </form>

          
           </div>
       </div>

       </div>
       </div>
    )
}



