import React, { Component } from 'react';
import QRCode from 'qrcode.react'

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function QrCode() {

    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');
  
    useEffect(async () => {

        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
        }

        setLoading(true);
        const data = await getRoute('/assets');
        setLoading(false);
        if (data) {
            setAssets(data.assets);
        }

        setCompany('http://isokoo.rw/');



    }, []);
    console.log(assets);

    if(!loading && !assets){

        return <p>No result found</p>; 
    }

    const result =  assets.map((asset, index) => {
        return (
            <div className='qrcodeDisplay'>
              <QRCode id={index}  
                      value={"http://192.168.43.64:3000/check-asset/"+asset.id}  
                      className="qrcodeDisplay"/>

                <h4 className='text-primary text-center'>
                    {asset.tbl_assetDesc}
                </h4>
            
            </div>)
     });

        return (
            <div>
            <Header/> 
      
            <div class="wrapper">
              <Menu role={userRole} full={userFullName}  />

            <div className="content-wrapper bg-white">
                {loading ? 
                  <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
                : 
                <div>
                   <div className="qrCodes">
                       <h4 className="newUserTitle bg-info text-center text-white">
                          All QR CODES
                       </h4>
                   </div>

                   {result }

                   

                 </div>}
               
     

               
            </div>

            </div>
            </div>
        )
    
}
