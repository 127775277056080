import React from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allcustodianRows} from "../../allData";
// Delete
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewCustodianAsset() {

  const [custodAssets, setCustodyAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [departs, setDeparts] = useState([]);
  const [departLoading, setDepartLoading] = useState(false);

  const [logRole, setLogRole] = useState(false);
  const [initialCustodAssets, setInitialCustodyAssets] = useState([]);

  const [currentDepart, setCurrentDepart] = useState([]);
  const [loadCurrDepart, setLoadCurrDepart] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const onChangeDepartment = ({ target }) => {
    const departId = target.value;

    if(departId === null){

      setCustodyAssets(custodAssets);
      setLoadCurrDepart(" Custodian ");
      
    }else{

      const newDeparts = departs.filter(newDepart => 
        newDepart.id === departId);
        setCurrentDepart(newDeparts);

        setLoadCurrDepart(" Department ");

      const newCustodAssets = initialCustodAssets.filter(newCustodAsset => 
        newCustodAsset.staff.tbl_departmentID === departId);
        setCustodyAssets(newCustodAssets);

    }

    
  }

  useEffect(async () => {


    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    } 

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 

        if(decoded.staffFoundRole.name == "custodian" 
          || decoded.staffFoundRole.name == "IT" || 
          decoded.staffFoundRole.name == "HR" || 
          decoded.staffFoundRole.name == "lab-manager"  
        ){

          setLoading(true);
          const data = await getRoute('/custody-assets/emp/'+decoded.id);
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);
          }
        
        }else if(decoded.staffFoundRole.name == "asset-manager"){

          setLogRole(decoded.staffFoundRole.name);
          setLoading(true);
          
          const data = await getRoute('/custody-assets');
          setLoading(false);
          if (data) {
            setCustodyAssets(data.custodAssets);

            setInitialCustodyAssets(data.custodAssets);
          }

          setDepartLoading(true);
          const departData = await getRoute('/departs');
          setDepartLoading(false);
          if (departData) {
              setDeparts(departData.departs);
          }



        }

        setLoadCurrDepart(" Custodian ");



      }


  }, []);
  //console.log(custodAssets)

        // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allcustodianRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data

    if(!custodAssets){

      return <p>Loading .....</p>
    }

    const tableData = custodAssets.map((custodAsset, index) => {
      const { asset, staff, user, assetSubtypeInfo } = custodAsset;
      
      return {
          ...custodAsset,
          assetName: asset.tbl_assetDesc,

          assetCode: asset.tbl_assetCode,
          staffName: staff.tbl_staffFullName,

          staffNid: staff.tbl_staffNID,
          userFullName: user.tbl_staffFullName,

          from : new Date(custodAsset.tbl_custodassetFrom).getDate()+
          "/"+(new Date(custodAsset.tbl_custodassetFrom).getMonth()+1)+
          "/"+new Date(custodAsset.tbl_custodassetFrom).getFullYear(),

          to : new Date(custodAsset.tbl_custodassetTo).getDate()+
          "/"+(new Date(custodAsset.tbl_custodassetTo).getMonth()+1)+
          "/"+new Date(custodAsset.tbl_custodassetTo).getFullYear(),

          item: assetSubtypeInfo.tbl_subtypeName +" "+assetSubtypeInfo.type.tbl_typeName,



      }
  });

    const columns = [
        { field: 'id', headerName: 'No', width: 50 },
        {
          field: 'assetCode',
          headerName: 'Asset Code',
          width: 180,
        },
       
        {
            field: 'staffName',
            headerName: 'Employee Name',
            width: 180,
        },


        {

          field: 'from',
          headerName: 'From',
           width: 120,

        },           
          
        {
          field: 'to',
          headerName: 'To',
          width: 120,
        }, 

        {
          field: 'item',
          headerName: 'Item',
          width: 150,
        }, 

        {
          field: 'userFullName',
          headerName: 'Recorded By',
          width: 180,
        }, 
        
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            
            renderCell: (params)=>{
                return(
                    <>

                    <Link to={"/showCustodianAsset/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white"/>
                        </button>
                    </Link>
                    <Link to={"/request/"+params.row.id}>
                      <button className="userListEdit bg-info">
                        Request
                      </button>
                    </Link>

                    <Link to={"/custodianAssignedAssetReport/"+params.row.id}>
                    <button className="userListEdit bg-success">
                        ReAssign Asset
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    {/* <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/> */}
                   
                    </>
                )
            }
          },
      
      ];



// End Display Data
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
             {/* DISPLAYING ASSET */}

        <div className="newUserTitle text-white bg-info">         
        <>               
            
            <label className="destroyedHeaderDisplay"> 
              All Asset's assigned to 
                <b>{loadCurrDepart}</b>
            </label>
            
            
            </>
        
        
         </div>
         {loading ? 
            <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
            <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
            }

              {/* Footer Call */}
              <div className="footerHeight1">
           <hr />
                       
       </div>

            
        </div>
        </div>

        </div>
        </div>
    )
}
