import React, { Component } from 'react'


export default class DestroyedReport extends Component {
    render() {
        return (
            <div className="content-wrapper bg-white"> 
            <div className="destroyedReport">Destroyed Report</div>
            
                
            </div>
        )
    }
}
