import React from 'react'
import { useState, useEffect } from "react";
import {getRoute} from '../../api/methods';

import {supplierRows} from "../../allData";
import {DeleteOutline} from "@material-ui/icons";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { Send, Close } from "@material-ui/icons";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ViewSupplier() {

  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialSupplier, setInitialSupplier] = useState([]);

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  const onChangeSector = ({ target }) => {
    const sectorName = target.value;
    if (sectorName.toLowerCase() === 'all') {
      setSuppliers(initialSupplier);
      return;
    }
    console.log(sectorName)
    const newData = initialSupplier.filter(supplier => supplier.sector.tbl_sectorName === sectorName);
    setSuppliers(newData);
  }

  const fetchData = async() => {

    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);
    }

    setLoading(true);
    const data = await getRoute('/suppliers');
    setLoading(false);
    if (data) {
      setSuppliers(data.suppliers);
      setInitialSupplier(data.suppliers);
    }     
  }

  useEffect(() => {
    fetchData();    
  }, []);
  console.log(suppliers)

        // Display Employee
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(supplierRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    const tableData = suppliers.map((supplier, index) => {
      const { sector } = supplier;
      
      return {
          ...supplier,
          sectorName: sector.tbl_sectorName,
      }
  });


    // Delete Data


    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
     
        { field: 'tbl_supplierFullName', headerName: 'Full Name', width: 230 },
      
        
       
        { field: 'tbl_supplierNid', headerName: 'NID', width: 230 },
        { field: 'tbl_supplierTin', headerName: 'tin', width: 230 },
        

          {
            field: 'tbl_supplierPhone',
            headerName: 'Phone',
            width: 120,
          },
 

          {
            field: 'sectorName',
            headerName: 'Sector ',
            width: 120,
          },

       
        /*{
            field: 'tbl_supplierStatus',
            headerName: 'Condition',
            width: 180,
          },
        */
         
        
          
          {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params)=>{
                return(
                    <>
                    <Link to={"/showSupplier/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-eye text-white" />
                        </button>
                    </Link>
                    <Link to={"/editSupplier/"+params.row.id}>
                    <button className="userListEdit bg-info">
                        <i className="fas fa-edit text-warning" />
                        </button>
                    </Link>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  
                    <DeleteOutline  className="userListDelete text-danger" onClick={()=>handleDelete(params.row.id)}/>
                    </>
                )
            }
          },
      
      ];
      

// End DISPLAY EMPLOYEE
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  />
        <div className="content-wrapper bg-white">
        <div className=" viewEmployee">

              {/* DISPLAYING ASSET */}
      
      <div className="newUserTitle text-white bg-info">         
        <>               
            
            <label className="destroyedHeaderDisplay"> All Suppliers</label>
            
            
           
            

            </>
        
        
         </div>
       
         {loading ? 
            <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
            <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
          }
       
       <div className="footerHeight1">
           <hr />
                       
       </div>
       {/* Fotter Call */}
       {/* <Footer /> */}

            </div>
            </div>

            </div>
            </div>
    )
}
