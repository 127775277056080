import React from 'react'

// Icons
import {Close, DeleteOutline, Send} from "@material-ui/icons";
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddCondition() {

    const [condName, setCondName] = useState('');
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if(localStorage.adminEmailToken){
        
            const decoded = jwt_decode(localStorage.adminEmailToken); 
      
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
      
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
      
        } 
      }, []);


    const saveCondition = (event)  =>  {
        event.preventDefault();

        if(condName.trim() == '' || condName.trim() == null ){

            alert("Name is required");
            return false;

        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_conditionName": condName,
                "tbl_conditionToken": randomString(10),
                "tbl_conditionStatus": true,
            }

            setLoading(true);
            const data =  postRoute('/conditions',postData );
            
            if (data) {

                alert(condName+" has been added");
                setLoading(false);

                history.push("/conditionReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }

    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
        <div className="addAssetType">

            <h3 className="newUserTitle text-center text-white bg-info">Adding  Condition </h3>
            <form className="newUserForm" onSubmit={saveCondition}>
            <div className="newUserItem">
                    <label>ConditionName</label>
                    <input onChange={(e) => setCondName(e.target.value)} type="text" placeholder="Good" name="addCondition" id="addCondition" required />
                </div>      
            
               {/* <div className="buttons">                */}
                <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button>
                */}
                {/* </div> */}
            </form>
            
            </div>
        </div>

        </div>
        </div>
    )
}
