import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';

//export default class Menu extends Component {
export default function Menu(props) {

    //render() {
        return (
            <div>
               <aside className="main-sidebar sidebar-dark-primary bg-info elevation-4">
    {/* Brand Logo */}
    <Link to="/Dashboard" className="brand-link">
      <img src="dist/img/logo.png" alt="UR Logo" className="image1" style={{height:"50px%", width:"100%"}} />
   
    </Link>
    {/* Sidebar */}
    <div className="sidebar">
      {/* Sidebar user panel (optional) */}
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        {/* <div className="image">
          <img src="dist/img/etienno.jpg" className="img-circle elevation-2" alt="User Image" />
        </div> */}
        <div className="info">
          <Link to="/Dashboard" className="d-block text-white">
            {props.full}
          </Link>
          <span className="brand-text font-weight-bold text-white">{props.role}</span>
        </div>
      </div>
      {/* Sidebar Menu */}

      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}

           {props.role === 'asset-manager' ? 
              <div>

                 <li className="nav-item">
              <Link to="/Dashboard" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                Employee Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

              { /*    
              <li className="nav-item">
              <Link to="/employees" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Employee</p>
                  </Link>
                
              </li>*/}
              
              
              <li className="nav-item">
              <Link to="/addDepartment" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Department</p>
                  </Link>
              </li>

              <li className="nav-item">
              <Link to="/addField" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Field</p>
                  </Link>
              </li>

              <li className="nav-item">
              <Link to="/addLevel" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Level</p>
                  </Link>
              </li>
              
              <li className="nav-item">
              <Link to="/supplier" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Supplier</p>
                  </Link>
              </li>
            </ul>
          </li>

         

          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
               Asset Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
           
              <li className="nav-item">
              <Link to="/addNewAsset" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Asset</p>
                  </Link>
                
              </li>

              <li className="nav-item">
              <Link to="/addAssetType" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Asset Type</p>
                  </Link>
                
              </li>

              <li className="nav-item">
              <Link to="/addAssetSubType" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Asset Sub Type</p>
                  </Link>
                
              </li>

              <li className="nav-item">
              <Link to="/addFacility" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Facility</p>
                  </Link>
                
              </li>

              
              <li className="nav-item">
              <Link to="/sector" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Sector</p>
                  </Link>
                
              </li>
              
              <li className="nav-item">
              <Link to="/assetAudit" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Audit</p>
                  </Link>                
              </li>
              <li className="nav-item">
              <Link to="/assetCondition" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Condition</p>
                  </Link>                
              </li>


            </ul>
          </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fa fa fa-file" />
              <p>
                Employees Reports
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
           
              <li className="nav-item">
              <Link to="/employeesReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Employees</p>
                  </Link>
                
              </li>
              
              
              <li className="nav-item">
              <Link to="/departmentreport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Department</p>
                  </Link>
              </li>
              
              <li className="nav-item">
              <Link to="/fieldReport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Field</p>
                  </Link>
              </li>
                
              <li className="nav-item">
              <Link to="/levelReport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Level</p>
                  </Link>
              </li>
              <li className="nav-item">
              <Link to="/suppliersReport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Suppliers</p>
                  </Link>
              </li>
            </ul>
          </li>

            

          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fa fa fa-file" />
              <p>
                Assets Reports
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
           
              <li className="nav-item">
              <Link to="/registeredAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Assets</p>
                  </Link>
                
              </li> 

                 <li className="nav-item">
              <Link to="/registeredAssetTypeReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Asset Type</p>
                  </Link>
                
              </li> 

                <li className="nav-item">
              <Link to="/registeredAssetSubTypeReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Asset Sub Type</p>
                  </Link>
                
              </li> 
              <li className="nav-item">
              <Link to="/registeredFacilitiesReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Facilities</p>
                  </Link>
                
              </li> 

              {/*
              <li className="nav-item">
              <Link to="/destroyed" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Destroyed</p>
                  </Link>
                
              </li>*/}

               <li className="nav-item">
              <Link to="/maintainceRequestReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Maintainance Request</p>
                  </Link>
                
              </li>
              <li className="nav-item">
              <Link to="/maintainceAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Maintained Asset</p>
                  </Link>
                
              </li> 
              <li className="nav-item">
              <Link to="/sectorReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>All Sectors</p>
                  </Link>
                
              </li>  

              <li className="nav-item">
              <Link to="/custodianAssignedAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Custodian's Assigned</p>
                  </Link>                
              </li> 
              
              <li className="nav-item">
              <Link to="/conditionReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Condition</p>
                  </Link>                
              </li> 
              <li className="nav-item">
              <Link to="/auditReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Audit</p>
                  </Link>                
              </li> 
            
                  
                             
              
            </ul>
          </li>

          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fa fa fa-file" />
              <p>
                 Movement Reports
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
           
              <li className="nav-item">
              <Link to="/assetRenting" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Rent </p>
                  </Link>
                
              </li> 
              <li className="nav-item">
              <Link to="/allReturnedAssets" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Returned </p>
                  </Link>
                
              </li> 
              <li className="nav-item">
              <Link to="/asset-mvt" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Movement</p>
                  </Link>                
              </li>    

              <li className="nav-item">
              <Link to="/depart-asset" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>
                    Assets For Department
                  </p>
                  </Link>                
              </li>  

              <li className="nav-item">
              <Link to="/export-asset" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>
                    Export Assets Excel
                  </p>
                  </Link>                
              </li>    

              <li className="nav-item">
              <Link to="/export-depart-asset" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>
                    Export Department Assets 
                  </p>
                  </Link>                
              </li>      
                                
              
            </ul>
          </li>
                  
          <li className="nav-item">
            <Link to="qrcodes" className="nav-link">
              <i className="nav-icon fas fa-th" />
              <p>
                QR Codes
                
              </p>
            </Link>
          </li>
          </div>

          : null   /*  End of asset manager*/}




          {/* HR */}

          {props.role === 'HR' ? 
              <div>

          <li className="nav-item">
              <Link to="/Dashboard" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard HR</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                 Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

              {/*       
              <li className="nav-item">
              <Link to="/employees" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Employee</p>
                  </Link>
                
              </li> */}
              
              
              <li className="nav-item">
              <Link to="/addDepartment" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Department</p>
                  </Link>
              </li>

              <li className="nav-item">
              <Link to="/addField" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Field</p>
                  </Link>
              </li>

              <li className="nav-item">
              <Link to="/addLevel" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Level</p>
                  </Link>
              </li>
              
            </ul>
          </li>


          
          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fa fa fa-file" />
              <p>
                 Reports
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
           
              <li className="nav-item">
              <Link to="/employeesReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Employees</p>
                  </Link>
                
              </li>
              
              
              <li className="nav-item">
              <Link to="/departmentreport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Departments</p>
                  </Link>
              </li>
              
              <li className="nav-item">
              <Link to="/fieldReport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Fields</p>
                  </Link>
              </li>
                
              <li className="nav-item">
              <Link to="/levelReport" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Levels</p>
                  </Link>
              </li>

              <li className="nav-item">
                <Link to="/custodianAssignedAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Assigned Assets</p>
                </Link>
                
              </li>
            </ul>
          </li>

          </div>
          : null }


          {/* END HR */}


          


          {/* IT */}

          {props.role === 'IT' ? 
              <div>

          <li className="nav-item">
              <Link to="/Dashboard" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard IT</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                 Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

              <li className="nav-item">
                <Link to="/employees" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Add Employee</p>
                </Link>
                
              </li>
                     
              <li className="nav-item">
              <Link to="/employeesReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Employees</p>
                  </Link>
                
              </li>

                     
              <li className="nav-item">
              <Link to="/custodianAssignedAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Assigned Assets</p>
                  </Link>
                
              </li>
               
              {/*
              <li className="nav-item">
              <Link to="/employeeAcount" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Add Account</p>
                  </Link>
              </li>
              <li className="nav-item">
              <Link to="/viewEmployeeAcount" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Accounts</p>
                  </Link>
              </li>  */}

            </ul>
          </li>
          </div>
          : null }

          {/* END IT */}

          {/* CUSTODIAN */}

          {props.role === 'custodian' ? 
              <div>
          <li className="nav-item">
              <Link to="/Dashboard" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard CUSTODIAN</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                 Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

                     
              <li className="nav-item">
              <Link to="/custodianAssignedAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Assigned Assets</p>
                  </Link>
                
              </li>
               
             

            </ul>
          </li>
          </div>

          : null }


          {/* END CUSTODIAN */}

          {/* security guard */}

          {props.role === 'guard' ? 
              <div>

          <li className="nav-item">
              <Link to="/DashboardSecurity" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard Security</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                 Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

                     
              <li className="nav-item">
              <Link to="/DashboardSecurity" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Check Asset</p>
                  </Link>
                
              </li>

              {/*   
              <li className="nav-item">
              <Link to="#" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Register Asset</p>
                  </Link>
                
              </li> 
              */}
              </ul>
          </li>
          </div>
          : null }


          {/* end security guard */}

          {/* LAB MANAGER */}

          {props.role === 'lab-manager' ? 
              <div>
          
          <li className="nav-item">
              <Link to="/registeredFacilitiesReport" className="nav-link bg-white active">
               
                  <i className="fas fa-home"/>
                  <p> &nbsp; Dashboard Lab Manager</p>
                  </Link>
                
              </li>


          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link bg-white active">
              <i className="nav-icon fas fa-cog" />
              <p>
                 Settings
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">

                     
              <li className="nav-item">
              <Link to="/custodianAssignedAssetReport" className="nav-link">
               
                  <i className="far fa-circle nav-icon" />
                  <p>Managed Assets</p>
                  </Link>
                
              </li>
              
              </ul>
          </li>
          </div>

          : null }

          {/* END LAB MANAGER */}

            

    

       
         
        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
            </div>
        )
    //}
}
