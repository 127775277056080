import React from 'react'

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";

// Icons
import {Close, DeleteOutline, Send} from "@material-ui/icons";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AddAssetType() {

    const [typeName, setTypeName] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [lifespan, setLifespan] = useState('');
    let history = useHistory();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {

        if(localStorage.adminEmailToken){

           const decoded = jwt_decode(localStorage.adminEmailToken); 
  
           setUserEmail(decoded.tbl_staffEmail);
           setUserRole(decoded.staffFoundRole.name);
  
           setUserFullName(decoded.tbl_staffFullName);
          setUserId(decoded.id);

        }
  
    });

    const saveType = (event)  =>  {
        event.preventDefault();

        if(typeName.trim() == '' || typeName.trim() == null ){

            alert("Name is required");
            return false;

        }else if(lifespan.trim() == '' || lifespan.trim() == null ){

            alert("Name is required");
            return false;

        }else{

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = {

                "tbl_typeName": typeName,
                "tbl_typeToken": randomString(5),
                "tbl_typeStatus": true,

                "tbl_typeLifeSpan": lifespan
            }

            setLoading(true);
            const data =  postRoute('/types',postData );
            
            if (data) {

                alert(typeName+" has been added");
                setLoading(false);

                history.push("/registeredAssetTypeReport");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }


    return (

        <div>
        <Header/> 

        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  />  

        <div className="content-wrapper bg-white">
        <div className="addAssetType">

            <h3 className="newUserTitle text-center text-white bg-info">Adding  Asset Type </h3>
            <form className="newUserForm" onSubmit={saveType}>
            <div className="newUserItem">
                <label>Asset Type Name</label>
                <input onChange={(e) => setTypeName(e.target.value)} type="text" placeholder="Laptop" name="assetType" id="assetType" required />
            </div>
            
            <div className="newUserItem">
                <label>Lifespan</label>
                <input onChange={(e) => setLifespan(e.target.value)} type="number" placeholder="5 Years" name="assetType" id="assetType" required />
            </div>           
                
               
                
               {/* <div className="buttons">                */}
                <button className="newUserButton bg-info"><Send className="text-warning"/> Add</button>
                {/* <button className="newUserCancel bg-primary"><Close className="text-warning"/> &nbsp; Cancel</button>
                */}
                {/* </div> */}
            </form>
            
            </div>
        </div>

        </div>
        </div>
    )
}
