import React, { Component } from 'react';
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

import Header from '../header/Header';
import Menu from '../menu/Menu';

import jwt_decode from 'jwt-decode';

//export default class Login extends Component {
export default function Login() {

    let history = useHistory();

    if(localStorage.adminEmailToken){

        history.push("/Dashboard");

    }

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const [loading, setLoading] = useState(false);

    const [logDetail, setLogDetail] = useState([]);
    
    const LoginNow = (event)  =>  {
        event.preventDefault();

        if(email.trim() == '' || email.trim() == null ){

            alert("EMail is required");
            return false;

        }else if(pass.trim() == '' || pass.trim() == null ){

            alert("Password is required");
            return false;

        }else{

            //localStorage.setItem('usertoken', res.data)
            //return res.data;

            const userData = {

                "logMail": email,
                "logPass": pass,
            }

            axios.post('http://localhost:8080/api/v1/staffs/login', userData)
        
            .then(res =>{

                if(res.data.token != null ){

                    localStorage.setItem('adminEmailToken', res.data.token);

                    const decoded = jwt_decode(localStorage.adminEmailToken); 

                    if(decoded.staffFoundRole.name ==='custodian'){

                        history.push("/custodianAssignedAssetReport");
                      
                      
                      }else  if(decoded.staffFoundRole.name ==='HR'){
                    
                        history.push("/employeesReport");
                      
                      }else if(decoded.staffFoundRole.name ==='IT'){
                    
                        history.push("/employeesReport");
                      
                      }else if(decoded.staffFoundRole.name ==="guard"){
                    
                        history.push("/DashboardSecurity");
                      
                      }else if(decoded.staffFoundRole.name ==='lab-manager'){
                    
                        history.push("/custodianAssignedAssetReport");
                      
                      
                      }else if(decoded.staffFoundRole.name ==='asset-manager'){
                    
                        history.push("/Dashboard");
                      
                      
                      }
                    


                }else if(res.data.error){

                    alert("Something went wrong");
                    return false;


                }
        
    
            })
        
            .catch(err =>{
        
                console.log(err)
                
            });

            /*

            setLoading(true);

            const data = postRoute('/staffs/login', userData );

            if(data){

                console.log(data);

            }else{

                alert("Something went wrong");

            }
            */




        }
        

    }

    const paperStyle={padding :20,height:'80vh',width:440, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#201bbd'}
    const btnstyle={margin:'8px 0'}

        return (

            <div>
            <Header/> 
      
            <div class="wrapper">
              <Menu role="login" full="Please Login"  /> 

            <div class="col-md-12 mt-5"><br />
                  <Grid class="col-md-12">
            <Paper elevation={10} style={paperStyle} >
                <Grid align='center'>
                     <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <h2 class="text-primary">OIMS SignIn</h2>
                </Grid>
                <hr class="bg-light"/>
                <form   >                
                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control mt-3" fullWidth required placeholder='Enter Email'/><br/>
                <input onChange={(e) => setPass(e.target.value)} type="password" className="form-control mt-3" fullWidth required placeholder='Enter Password'/>

                <TextField label='Password' placeholder='Enter password' type='password' fullWidth required hidden/>
                <FormControlLabel
                    control={
                    <Checkbox
                        name="checkedB"
                        color="primary"
                    />
                    }
                    label="Remember me"
                 />
                 {/*<button onClick={LoginNow} className="newUserButton bg-primary">Sign In</button>*/}
                 <Button onClick={LoginNow} color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>
                </form>
                <hr class="bg-light"/>
                <Typography >
                     <Link to="/forgot" class="text-danger" >
                        Forgot password ?
                </Link>
                </Typography>
                
              
            </Paper>
        </Grid>

        <footer class="footer fixed-bottom text-center text-white">Alright Reserver By <span class="text-white">University of Rwanda </span></footer>
                 
            </div>

            </div>
            </div>
        )
    }

