import React from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

// Icons
import {DeleteOutline} from "@material-ui/icons";

import {allcustodianRows} from "../../allData";
// Delete
import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function AssetMovement() {

  const [movedAssets, setMovedAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [departs, setDeparts] = useState([]);
  const [departLoading, setDepartLoading] = useState(false);

  const [logRole, setLogRole] = useState(false);
  const [initialMovedAssets, setInitialMovedAssets] = useState([]);

  const [currentDepart, setCurrentDepart] = useState([]);
  const [loadCurrDepart, setLoadCurrDepart] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');

  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(async () => {

    if(localStorage.adminEmailToken){
        
      const decoded = jwt_decode(localStorage.adminEmailToken); 

      setUserEmail(decoded.tbl_staffEmail);
      setUserRole(decoded.staffFoundRole.name);

      setUserFullName(decoded.tbl_staffFullName);
     setUserId(decoded.id);

    }

      if(localStorage.adminEmailToken){

        const decoded = jwt_decode(localStorage.adminEmailToken); 

        if(decoded.staffFoundRole.name == "custodian" 
          || decoded.staffFoundRole.name == "IT" || 
          decoded.staffFoundRole.name == "HR" || 
          decoded.staffFoundRole.name == "lab-manager"  
        ){

          setLoading(true);
          const data = await getRoute('/movements');
          setLoading(false);
          if (data) {
            setMovedAssets(data.movements);
          }
        
        }else if(decoded.staffFoundRole.name == "asset-manager"){

          setLogRole(decoded.staffFoundRole.name);
          setLoading(true);
          
          const data = await getRoute('/movements');
          setLoading(false);
          if (data) {
            setMovedAssets(data.movements);

            setInitialMovedAssets(data.movements);
          }

        }





      }


  }, []);
  //console.log(custodAssets)

        // Diplay Data
 // Delete Data
    // userData===== From dummyData File
    const [data,setData] = useState(allcustodianRows)

    const handleDelete = (id)=>{
        setData(data.filter(item=>item.id != id)) ;
    };

    // Delete Data

    if(!movedAssets){

      return <p>Loading .....</p>
    }

    const tableData = movedAssets.map((movedAsset, index) => {
      const { 
          custodFrom, 
          custodTo, 
          
          staffMoves, 
          fromFacility, 
          
          toFacility, 
          assetGot, 
          
          fromStaff, 
          toStaff, 

          typeInfo,

        } = movedAsset;
      
      return {
          ...movedAsset,
          assetName: assetGot.tbl_assetDesc,

          assetCode: assetGot.tbl_assetCode,
          departFrom: fromStaff.department.tbl_departmentName,

          departTo: toStaff.department.tbl_departmentName,
          facFrom : fromFacility.tbl_facilityName,

          facTo : toFacility.tbl_facilityName,
          moveDate : new Date(movedAsset.createdAt).getDate()+
          "/"+(new Date(movedAsset.createdAt).getMonth()+1)+
          "/"+new Date(movedAsset.createdAt).getFullYear(),

          item: assetGot.subtype.tbl_subtypeName + " - "+typeInfo.tbl_typeName,
          recordBy : staffMoves.tbl_staffFullName,



      }
  });

    const columns = [
        { field: 'id', headerName: 'No', width: 100 },
        {
          field: 'assetCode',
          headerName: 'Asset Code',
          width: 180,
        },
       
        {
            field: 'departFrom',
            headerName: 'Origin Dpt',
            width: 150,
        },

        {
            field: 'facFrom',
            headerName: 'Origin Off',
            width: 150,
        },

        {
          field: 'departTo',
          headerName: 'Destination Dpt',
          width: 180,
        },           
          
        {
          field: 'facTo',
          headerName: 'Destination Off',
          width: 180,
        }, 

        {
          field: 'moveDate',
          headerName: 'Date',
          width: 120,
        }, 

        {
            field: 'item',
            headerName: 'Item',
            width: 180,
        }, 


        {
          field: 'recordBy',
          headerName: 'Recorded By',
          width: 180,
        }, 
        
      
      ];



// End Display Data
    return (

      <div>
      <Header/> 

      <div class="wrapper">
        <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper  bg-white">
        <div className="viewEmployee">
             {/* DISPLAYING ASSET */}

        <div className="newUserTitle text-white bg-info">         
        <>               
            
            <label className="destroyedHeaderDisplay"> 
              All Assets movement 
            </label>
            
            
            </>
        
        
         </div>
         {loading ? 
            <center><img  src={"dist/img/loading.gif"} alt="Loading...." /></center> 
            :
            <DataGrid className="dataGrid" rows={tableData} disableSelectionOnClick columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection/>
            }

              {/* Footer Call */}
              <div className="footerHeight1">
           <hr />
                       
       </div>

            
        </div>
        </div>

        </div>
        </div>
    )
}
