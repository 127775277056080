import React from 'react'

import { Send} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function ReturnedAsset() {

    const { id } = useParams();

    const [rent, setRent] = useState(null);
    const [loading, setLoading] = useState(false);

    const [conditions, setConditions] = useState([]);

    const [rentId, setRentId] = useState(null);
    const [condition, setCondition] = useState('');

    const [note, setNote] = useState('');
    let history = useHistory();

    const [staff, setStaff] = useState('');

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    const [assetId, setAssetId] = useState(null);

    useEffect(async () => {


        if(localStorage.adminEmailToken){
    
            const decoded = jwt_decode(localStorage.adminEmailToken); 
    
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
    
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
    
        }

        setLoading(true);
        const data = await getRoute('/rent/view/'+id);
        setLoading(false);
        if (data) {
            setRent(data.rentasset);

            setAssetId(data.rentasset.assetTobeRent.id);
        }

        //conditions
        const conditionData = await getRoute('/conditions');
        if (conditionData) {
            setConditions(conditionData.conditions);
        }

        setRentId(id);

        const decoded = jwt_decode(localStorage.adminEmailToken); 
        setStaff(decoded.id);

    }, []);

    if(!loading && !rent){

        return <p>No result found</p>; 
    }

    const saveReturned = (event)  =>  {
        event.preventDefault();

        if(rentId.trim() == '' || rentId.trim() == null ){

            alert("Something went wrong");
            return false;

        }else if(condition.trim() == '' || condition.trim() == null ){

            alert("Condition of asset is required");
            return false;

        }else if(note.trim() == '' || note.trim() == null ){

            alert("Comment is required");
            return false;

        }else{  

            function randomString(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+=-';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                   result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }


            const postData = { 
                
                "tbl_rent_assetID": rentId,
                "tbl_returnComment": note,
                "tbl_returnToken": randomString(5),
                "tbl_returnReceived": staff,
                "tbl_returnStatus": true,

                "tbl_assetID": assetId,

            }

            setLoading(true);
            const data =  postRoute('/returned',postData );
            
            if (data) {

                alert("Returned asset has been registered");
                setLoading(false);

                history.push("/allReturnedAssets");

            }else{

                setLoading(false);

                alert("Something went wrong");
                return false;

            }  


        }
        

    }



    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">

            {loading ? 
                <center><img  src={"../dist/img/loading.gif"} alt="Loading...." /></center> 
            :
          <div className="returnedAsset">
<h3 className="bg-info newUserTitle text-center text-white">
    Returning  Asset:

    <b className="text-warning">
      {" "+rent.assetTobeRent.tbl_assetDesc} {" - "+rent.assetTobeRent.tbl_assetCode}
    </b> 
</h3>
            <form className="newUserForm"  onSubmit={saveReturned} >

                <div className="newUserItem">
                    <label>Condition</label>
                    <select onChange={(e) => setCondition(e.target.value)} name="auditCondition" id="auditCondition" className="newUserSelect">
                        <option value="">---Condition----</option>
                        { loading ? <option value="">Loading ...</option> : 
                          conditions.map(condition =>(
                            <option value={condition.id} >
                             {condition.tbl_conditionName}
                            </option>
                        )) }
                    </select>

                </div>
                <div className="newUserItem">
                    <label>Asset Note</label>
                    
                    <textarea onChange={(e) => setNote(e.target.value)} name="assetNote" id="assetNote" cols="30" rows="10" className="newUserSelect"></textarea>
                </div>
                 
                <button className="newUserButton bg-primary"><Send className="text-warning"/> Return</button>
                
            </form>


           </div> }
            
        </div>

        </div>
        </div>
    )
}
