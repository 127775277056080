import React from 'react'
import { Link } from 'react-router-dom';

import {Wc, CreditCard, MailOutline, PermIdentity, PhoneAndroid} from "@material-ui/icons";

import { useState, useEffect } from "react";
import {getRoute, postRoute } from '../../api/methods';

import { useHistory, useParams } from "react-router-dom";

import jwt_decode from 'jwt-decode';

import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function EditNewAsset() {
    const [asset, setAsset] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
  
    const [userFullName, setUserFullName] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(async () => {

        if(localStorage.adminEmailToken){
    
            const decoded = jwt_decode(localStorage.adminEmailToken); 
    
            setUserEmail(decoded.tbl_staffEmail);
            setUserRole(decoded.staffFoundRole.name);
    
            setUserFullName(decoded.tbl_staffFullName);
           setUserId(decoded.id);
    
        }

        setLoading(true);
        const data = await getRoute('/assets/asset/'+id);
        setLoading(false);
        if (data) {
            setAsset(data.asset);
        }
    }, []);

    console.log(asset);

    if(loading && !asset){

        return <p>No asset</p>; 
    }
    
    return (

        <div>
        <Header/> 
  
        <div class="wrapper">
          <Menu role={userRole} full={userFullName}  /> 

        <div className="content-wrapper bg-white">
            <div className="editNewAsset">

                         {/* <h1 className="userTitle"> Edit User</h1> */}
        {/* <h1 className="newUserTitle text-center text-white">Edit Employee .....</h1> */}
        <div className="userTitleContainer">
 
    
    </div>
    
    <div className="userContainer">
    <div className="userShow">
    <div className="userShowTop">
    <img src="https://www.zdnet.com/a/hub/i/r/2020/03/19/4d0e4e03-c980-43db-aa0f-2572aefcf89f/thumbnail/770x433/36d847f1d279f3a0cbaf29e1e9fc576f/surface-laptop-3-13-5-header.jpg" 
    alt="No Imange" className="userShowImg" />
    <div className="userShoeTopTitle">
    <span className="userShowUsername">
        {asset.tbl_assetDesc}
    </span>
    <span className="userShowUserTitle">
        {asset.facility.tbl_facilityName}
    </span>
    </div>
    </div>
    <div className="userShowBottom">
    <span className="userShowTitle text-dark">Asset Details</span>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark"/>
    <span className="userShowInfoTitle">{asset.tbl_assetAcquisValue}</span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">{asset.tbl_assetCode}</span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">Working</span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">{asset.createdAt}</span>
    </div>
    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        {asset.subtype.tbl_subtypeName}
    </span>
    </div>  

    <div className="userShowInfo">
    {/* Icon */}
    
    <CreditCard className="userShowIcon text-dark" />
    <span className="userShowInfoTitle">
        {asset.supplier.tbl_supplierFullName}
    </span>
    </div>  
  
  
    </div>
    </div>
    <div className="userUpdate">
        <h2 className="newUserTitleEdit text-center text-white bg-info">
    <span className="userUpdateTitle"><i className="fas fa-edit text-warning"/>&nbsp;&nbsp;Edit Asset Information</span>
    </h2>
    <form className="userUpdateForm">
    <div className="userUpdateLeft">
    
    <div className="userUpdateItem">
    <label>Description</label>
    <input type="text" placeholder="Laptop" 
    className="userUpdateInput"  name="desc" id="desc"/>
    </div>
    
    <div className="userUpdateItem">
    <label>Acquisition_Value</label>
    <input type="text" placeholder="5000" 
    className="userUpdateInput"
    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} 
     />
    </div>
    
    <div className="userUpdateItem">
    <label>Note</label>
    <input type="text" placeholder="Working" 
    className="userUpdateInput"/>
    </div>
    
    <div className="userUpdateItem">
    <label>Asset Type</label>

   <select name="assetSubType" id="assetSubType" className="userUpdateInput">
       <option value="Furniture">Furniture</option>
       <option value="Others">Others</option>
   </select>

    {/* <input type="text" placeholder="Laptop" 
    className="userUpdateInput"
     name="subType"/> */}
    </div>
    <div className="userUpdateItem">
    <label>Facility</label>
    <input type="text" placeholder="Muhabura" 
    className="userUpdateInput"
     name="facility"/>
    </div>
    
    
    </div>
    <div className="userUpdateRight">
        <div className="userUpdateUpload">
            
             <label htmlFor="file">
                 
             <img src="https://www.zdnet.com/a/hub/i/r/2020/03/19/4d0e4e03-c980-43db-aa0f-2572aefcf89f/thumbnail/770x433/36d847f1d279f3a0cbaf29e1e9fc576f/surface-laptop-3-13-5-header.jpg"
             alt="No image" className="userUpdateImg" title="Upload Image"/> 
                 
                 {/* <Publish className="userUpdateIcon"/> */}
                 
                 </label>
             <input type="file" id="file" style={{display:"none"}} />
        </div>
        <button className="userUpdateButton bg-info"><i className="fas fa-edit text-warning"/>&nbsp;&nbsp;Update</button>
    </div>
    </form>
    </div>
    </div>


    {/* Footer Call */}
    <div className="footerHeight1">
               <hr />
                           
           </div>
    </div>
            
        </div>

        </div>
            
            </div>
    )
}
